<template>
        <!-- 轮播图 -->
    <div class="pageControl-block flex-row flex-center">
      <div class="pageControl">
      <div class="pageControl-content">
        <div class="pageControl-content-wrapper">
          <swiper
            v-if="pageMenus.length>0"
            :options="swiperOption"
            :auto-update="true"
          >
            <swiper-slide
              :style="{'margin-right': 0,'background-color': item.bgColor}"
              v-for="(item,index) in pageMenus"
              :key="index"
              class="content-item"
            >
              <div @click="itemClick(item)">
                <img :src="`${item.icon}`" class="swiper-img" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev swiper-button-white"></div>
        <div class="swiper-button-next swiper-button-white"></div>
        <div class="swiper-pagination"  slot="pagination"></div>
      </div>
        <!-- 弹框 -->
        <qr-code-alert ref="codeAlert" :alertData="alertData"></qr-code-alert>
        <!-- 弹框End -->
      </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// If you use Swiper 6.0.0 or higher
import 'swiper/swiper-bundle.css'
import Swiper2, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper'

import QrCodeAlert from '../components/QrCodeAlert'

Swiper2.use([Navigation, Pagination, EffectFade, Autoplay])
export default {
  name: 'HomeSwiper',
  components: {
    Swiper,
    SwiperSlide,
    QrCodeAlert
  },
  directives: {
    swiper: directive,
  },
  props: {
    pageMenus: {
      type: Array,
      require: true
    }
  },
  // watch: {
  //   pageMenus(oldV, newV) {
  //     if (newV.length == 1) {
  //       this.swiperOption.loop = false
  //       this.swiperOption.autoplay = undefined
  //     }else {
  //       this.swiperOption.loop = true
  //       this.swiperOption.autoplay =  {//每张播放时长3秒，自动播放
  //           delay: 2000,
  //           stopOnLastSlide: false,
  //           disableOnInteraction: false,
  //       }
  //     }
  //   }
  // },
  data() {
    return {
      swiperOption: {
        //可视区域展示多少个滑块
        slidesPerView: 1,
        //每个滑块的margin-left 这里是20px
        spaceBetween: 0,
        freeMode: false,
        //是否开始循环模式
        loop: true,
        //每张播放时长3秒，自动播放
        autoplay: {
            delay: 2000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
        },
        //滑动速度
        speed:1000,
        // 
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          //type: 'fraction',
          //type : 'progressbar',
          //type : 'custom',
          progressbarOpposite: true, //使进度条分页器与Swiper的direction参数相反
          bulletElement : 'pagination', //设定分页器指示器（小点）的HTML标签。
          dynamicBullets: true,  //动态分页器，当你的slide很多时，开启后，分页器小点的数量会部分隐藏。
          dynamicMainBullets: 4, //动态分页器的主指示点的数量
          hideOnClick: true, //默认分页器会一直显示。这个选项设置为true时点击Swiper会隐藏/显示分页器。
          clickable: true, //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换。
          bulletClass : 'my-bullet',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active'
        },
        //配置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        //小手掌抓取滑动
        // grabCursor: true,
        //这里可以配置点击事件
        on: {
          click: function () {
            console.log('轮播图点击');
          },
        },
      },
      // *******************弹框******************************** *//
      alertData: {},
      minPromgramAlertData: {
          title1: '扫码咨询',
          title2: '耳穴库小程序提供国内海量的耳穴资料查询，穴位配方、答疑解惑，耳穴论文、书籍、耳穴人经验分享文案、身边耳穴师等',
          qrcodeUrl: require('@/assets/miniprogram.png'),
          title3: '请使用微信扫描二维码'
      },
      // 联系客服弹框数据
      contactsAlertData: {
        title1: '扫码咨询',
        title2: '请用微信扫码添加客服：耳穴堂明明',
        qrcodeUrl: require('@/assets/footer/qr_contact.jpeg'),
        title3: '请使用微信扫描二维码'
      },
      
      // *******************弹框******************************** *//
    }
  },
  methods: {
    itemClick(item) {
      console.log(item);
      if (item.alertType == 1) {
        // 小程序弹框
        this.showAlertAction(this.minPromgramAlertData)
      }else {
        window.open(item.url, '_blank')
      }
    },
    
    // *******************弹框******************************** *//
    showAlertAction(alertData) {
      if (alertData) {
        this.alertData = alertData
        this.$refs.codeAlert.showSelf()
      }
    }
    // *******************弹框******************************** *//
  },
}
</script>
<style lang="less">
  .home-container {
    width: 100vw;
  }

  .pageControl-block {
    width: 100vw;
    // background-color: #5e8af6;
    .pageControl{
       width: 100%;
    }
  }

  .pageControl-content {
    position: relative;
    width: 100%;
    // height: 360px;
    .pageControl-content-wrapper {
      width: 100%;
      height: 100%;
      // margin: 0 200px 0 200px;
      .content-item {
        width: 100%;
        // float: left;
        text-align: center;
        background: rgba(255, 255, 255, 0.4);
        // cursor: pointer;
        .item-name {
          font-size: 18px;
          color: #222328;
          font-weight: 400;
          margin: 31px 0 38px 0;
        }
        .item-description {
          font-size: 16px;
          color: #3a3d4b;
        }
        .swiper-img {
          width: 1200px;
          height: 360px;
        }
      }
    }
  }

  .swiper-button-prev, .swiper-button-next{
    top: 0;
    bottom: 0;
    color: #fff;
    margin: auto;
  }

  //穿透 所有在.wrapper下面的.swiper-pagination-bullet-active更改小圆点的背景颜色
.pageControl /deep/ .swiper-pagination-bullet-active {
  background: #fff;
}

.my-bullet {
  width: 80px;
  height: 10px;
  border-radius: 0;
  margin: 0 10px;
  background: #999;
}

.my-bullet-active {
  width: 80px;
  height: 10px;
  border-radius: 0;
  background-color: white;
}

// .swiper-pagination {
//   bottom: 20px;
//   left: 0;
//   right: 0;
//   margin: auto;
// }

// .swiper-container .swiper-pagination .swiper-pagination-bullet {
//   width: 32px;
//   height: 4px;
//   background: #999;
//   opacity: .2;
//   border-radius: 4px;
//   margin-left: 10px;
// }

// .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
//   width: 32px;
//   height: 4px;
//   background: #fff;
//   opacity: 1;
//   border-radius: 4px;
// }

</style>