<template>
  <div class="home-container flex-row flex-center">
    <!-- 轮播图 -->
    <home-swiper :pageMenus="pageMenus"></home-swiper>

  <!-- 菜单栏 -->

    <!-- <div v-if="isTop" class="menu-block flex-row flex-center">
      <div class="main-width flex-column flex-middle">
        <div v-for="menuItem,index in menuArr" :key="index" class="menu-item" :class="{'selected': index == selectedMenuIdx}" @click="menuTap(index)">{{menuItem.title}}</div>
      </div>
    </div> -->
    <div class="menu-block flex-row flex-center" :style="isTop ? 'top:0px':'top:64px'">
      <div class="main-width flex-column flex-middle">
        <div v-for="menuItem,index in menuArr" :key="index" class="menu-item" :class="{'selected': index == selectedMenuIdx}" @click="menuTap(index)">{{menuItem.title}}</div>
      </div>
    </div>

  <!-- 视频 -->
    <div id="video-block" ref="video-block" class="video-block main-width">
      <video-player class="video-img main-width" 
            ref="videoPlayer" 
            :playsinline="true" 
            :options="playerOptions">
      </video-player>
      <!-- <img src="@/assets/home/index_video@2x.png" class="video-img main-width" /> -->
    </div>

  <!-- 服务体系 -->
    <div id="system-block" ref="system-block" class="system-block flex-row flex-center">
      <div class="main-width flex-row flex-center">
        <!-- 标题 -->
        <div class="title-view flex-column flex-middle">
          <div class="line"></div>
          <div class="text">耳穴堂培训6S服务体系</div>
          <div class="line"></div>
        </div>
        <!-- 内容 -->
        <div class="content main-width flex-column flex-center">
          <!-- <img :src="choiceImg" class="item-img" /> -->
          <!-- 滑动图片 -->
          <div class="item-img">
          <swiper
            v-if="systemArr.length>0"
            :options="swiperOption"
            :auto-update="true"
            ref="mySwiper"
          >
            <swiper-slide
              :style="{'margin-right': 0}"
              v-for="(item,index) in systemArr"
              :key="index"
              class="content-item"
            >
              <div @click="itemClick(item)">
                <img :src="`${item.img}`" class="item-img" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
          <div class="choice-block flex-row flex-center flex-around">
            <div v-for="choicItem,index in systemArr" :key="index" class="choice-item flex-column flex-middle" @mouseenter="choiceTap(index)" >
              <img :src="choicItem.icon" class="choice-icon" />
              <div class="desc-block flex-row">
                <div class="title1">{{choicItem.title}}</div>
                <div class="title2">{{choicItem.desc}}</div>
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/home/index_tx_img@2x.png" class="consulting-img main-width" @click="showAlertAction(contactsAlertData)"/>
        <div class="more-ask text-center" @click="showAlertAction(contactsAlertData)">咨询更详细的教学服务</div>
      </div>
    </div>
    <!-- 服务体系End -->

    <!-- 八大优势 -->
      <div id="advantage-block" ref="advantage-block" class="advantage-block flex-row flex-center">
        <div class="main-width flex-row flex-center">
          <!-- 标题 -->
          <div class="title-view flex-column flex-middle">
            <div class="line"></div>
            <div class="text">耳穴八大优势</div>
            <div class="line"></div>
          </div>

          <!-- 图片列表 -->
          <div class="advant-img-list main-width flex-column flex-middle flex-between">
            <div v-for="imgItem,index in advantageArr" :key="index" class="advant-item flex-row">
              <div class="advant-top advant-half flex-column flex-middle flex-between" :style="imgItem.background">
                <div class="flex-row flex-left">
                  <div class="title1">{{imgItem.title}}</div>
                  <div class="title2">{{imgItem.enTitle}}</div>
                </div>
                <img  :src="imgItem.icon" class="advant-img right-view" />
              </div>
              <div class="advant-bottom advant-half flex-column flex-between">
                <div class="advant-bottom-half flex-row ">
                  <div v-for="contentItem,contentIdx in imgItem.contentArr" :key="contentIdx" class="">
                    <div v-if="contentIdx < 3" class="content-item">{{contentItem}}</div>
                  </div>
                </div>
                <div class="advant-bottom-half flex-row ">
                  <template v-if="imgItem.contentArr.length > 3">
                    <div v-for="contentItem,contentIdx in imgItem.contentArr" :key="contentIdx" class="">
                      <div v-if="contentIdx >= 3" class="content-item">{{contentItem}}</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    <!-- 八大优势End -->

    <!-- 学员反馈 -->
      <div id="feedback-block" ref="feedback-block" class="feedback-block main-width flex-row flex-center">
        <!-- 标题 -->
          <div class="title-view flex-column flex-middle">
            <div class="line"></div>
            <div class="text">学员反馈</div>
            <div class="line"></div>
          </div>
          
          <!-- 反馈信息 -->
          <div class="feedback-info flex-column flex-middle flex-around">
            <div v-for="feedbackInfoItem,feedbackInfoIdx in feedbackInfoArr" :key="feedbackInfoIdx" class="item flex-column flex-middle flex-center">
              <img :src="feedbackInfoItem.icon" class="icon" />
              <div class="feedbackinfo-right flex-row flex-left">
                <div class="num">{{feedbackInfoItem.num}}</div>
                <div class="title">{{feedbackInfoItem.title}}</div>
              </div>
            </div>
          </div>
          <!-- 反馈聊天展示 -->
          <div v-if="!$store.state.isCheck" class="feedback-chat-block main-width " :style="{backgroundImage: 'url(' + feedbackBg + ')'}">
            <!-- <img src="/src/assets/home/feedback/chat/chat_bg.png" class="chat-bg-img" /> -->
            <div class="content-block">
              <div v-for="contentItem,contentIdx in  feedbackContentArr" :key="contentIdx" class="chat-item flex-column flex-top" :style="`top:${contentItem.top}px;left:${contentItem.left}px;`">
                <img :src="contentItem.img" class="feedback-img" :class="{'big': !contentItem.content}" />
                <div v-if="contentItem.content" class="content">{{contentItem.content}}</div>
              </div>
            </div>
          </div>
          <div class="more text-center" @click="showAlertAction(contactsAlertData)">查看更多</div>
      </div>
    <!-- 学员反馈End -->

      <!-- 产品体系 -->
      <div ref="product-block">
        <production-list></production-list>
      </div>
    <!-- 产品体系End -->

    <!-- 弹框 -->
    <qr-code-alert ref="codeAlert" :alertData="alertData"></qr-code-alert>
    <!-- 弹框End -->
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import HomeSwiper from '../components/HomeSwiper.vue'
import QrCodeAlert from '../components/QrCodeAlert'
import ProductionList from '../components/ProductionList.vue';

export default {
  name: 'Home',
  components: {
    HomeSwiper,
    videoPlayer,
    QrCodeAlert,
    ProductionList
  },
  metaInfo: {
    mata: [
      {
        name: 'keywords',
        content: '耳穴培训,耳穴挂图,耳穴位,耳朵穴位图,耳朵穴位,耳针图谱,耳穴,耳针,耳针疗法,耳穴疗法,耳穴医学,耳医学,贴耳穴,学耳穴,耳穴联盟,耳穴加盟,耳疗,耳穴堂,耳穴库,耳穴淘宝,耳穴视频,耳穴培训,耳穴图,耳穴网站'
      },
      {
        name: 'description',
        content: '耳穴库网是耳穴公益性学习网站提供一站式耳穴培训,是青岛耳穴堂公司推出的集耳穴定位、疾病配方、耳穴培训,耳穴工具、视频教程、经验分享、传承基地、论坛于一体的综合性网站，耳穴库网致力成为国内专业的耳穴互联网平台。'
      }
    ]
  },
  data() {
    return {
      // *******************banner******************************** *//
      pageMenus: [
        {
          name: '名称4',
          icon: 'https://static.cdn.erxueku.com/exk/9.jpg',
          bgColor: '#e8f7ff',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNWVlMDVkMjYzYmY1ZV9pa3VxWUI3MiIsImNoYW5uZWxfaWQiOiIiLCJyZXNvdXJjZV9pZCI6IiJ9'
        },
         {
          name: '名称4',
          icon: 'https://static.cdn.erxueku.com/exk/g2.png',
          bgColor: '#eeebe2',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNjAzMWI1YTllNGIwZjE3NmFlZDMwMjg3IiwiY2hhbm5lbF9pZCI6IiIsInJlc291cmNlX2lkIjoiIn0'
        },
        {
          name: '3d',
          icon: 'https://static.cdn.erxueku.com/exk/app3.png',
          bgColor: '#2f4469',
          url: 'https://mp.weixin.qq.com/s/3OMwW-6jEqPNbnwc_DrUDg'
        },
        {
          name: '商城',
          icon: 'https://static.cdn.erxueku.com/exk/sc4.png',
          bgColor: '#ecaf5c',
          url: 'https://shop45067782.m.youzan.com/v2/feature/iUUH2VMZTh?kdt_id=44875614&scan=3&from=singlemessage&sl=undefined&st=1&sf=wx_sm&is_share=1&share_cmpt=native_wechat&from_uuid=8f130a9d-4d9f-3ca7-07a8-a8981167a3ea'
        },
        {
          name: '小程序',
          icon: 'https://static.cdn.erxueku.com/exk/xcx5.png',
          bgColor: '#fdf5ec',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNjAzMWI1YTllNGIwZjE3NmFlZDMwMjg3IiwiY2hhbm5lbF9pZCI6IiIsInJlc291cmNlX2lkIjoiIn0',
          alertType: 1
        }
      ],
      // *******************banner******************************** *//

      // *******************菜单栏******************************** *//
      isTop: false,//是否滑动菜单栏到顶部了
      selectedMenuIdx:0, //选中索引
      scrolltop: 0,//滑动距离顶部距离
      menuArr: [
        {
          title: '什么是耳穴',
          eleId: 'video-block'
        },
        {
          title: '耳穴堂培训6S服务体系',
          eleId: 'system-block'
        },
        {
          title: '耳穴八大优势',
          eleId: 'advantage-block'
        },
        {
          title: '学员反馈',
          eleId: 'feedback-block'
        },
        {
          title: '耳穴堂产品体系',
          eleId: 'product-block'
        }
      ],
      // *******************菜单栏******************************** *//

      // *******************服务体系******************************** *//
      choiceImg: require('@/assets/icons/index_right_one@2x.png'),
      systemArr: [
        {
          title: '入学评估',
          desc: '认真考察学员实际需求，量身打造激活精准评估',
          icon: require('@/assets/icons/index_right_one@2x.png'),
          img: require('@/assets/home/system/01.png')
        },
        {
          title: '技能规划',
          desc: '分析指导解决耳穴人实操担忧，提供捷径迈向技术新高度',
          icon: require('@/assets/icons/index_right_two@2x.png'),
          img: require('@/assets/home/system/02.png')
        },
        {
          title: '督学指导',
          desc: '陪伴鼓励关注学员成长，及时了解保证有效沟通',
          icon: require('@/assets/icons/index_right_three@2x.png'),
          img: require('@/assets/home/system/03.png')
        },
        {
          title: '考试认证',
          desc: '不怕比学历，实力重要，让你技术实操更有体系',
          icon: require('@/assets/icons/index_right_four@2x.png'),
          img: require('@/assets/home/system/04.png')
        },
        {
          title: '会员体系',
          desc: '致力于打造耳穴人的一站式学习服务平台',
          icon: require('@/assets/icons/index_right_five@2x.png'),
          img: require('@/assets/home/system/05.png')
        },
        {
          title: '合作加盟',
          desc: '提供技术、资源、平台，强强联手共创耳穴未来',
          icon: require('@/assets/icons/index_right_six@2x.png'),
          img: require('@/assets/home/system/06.png')
        },
      ],
      swiperOption: {
        //可视区域展示多少个滑块
        slidesPerView: 1,
        //每个滑块的margin-left 这里是20px
        spaceBetween: 0,
        freeMode: false,
        //是否开始循环模式
        loop: true,
        //滑动速度
        speed:1000,
        // 
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          //type: 'fraction',
          //type : 'progressbar',
          //type : 'custom',
          progressbarOpposite: true, //使进度条分页器与Swiper的direction参数相反
          bulletElement : 'pagination', //设定分页器指示器（小点）的HTML标签。
          dynamicBullets: true,  //动态分页器，当你的slide很多时，开启后，分页器小点的数量会部分隐藏。
          dynamicMainBullets: 4, //动态分页器的主指示点的数量
          hideOnClick: true, //默认分页器会一直显示。这个选项设置为true时点击Swiper会隐藏/显示分页器。
          clickable: true, //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换。
          bulletClass : 'my-bullet',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active'
        },
        //配置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        //小手掌抓取滑动
        // grabCursor: true,
        //这里可以配置点击事件
        on: {
          click: function () {
            console.log('轮播图点击');
          },
        },
      },
      // *******************服务体系******************************** *//

      // *******************八大优势******************************** *//
      advantageArr: [
        {
          icon: require('@/assets/home/advantage/adv_01.png'),
          title: '疗法安全',
          enTitle: '',
          background: 'background: linear-gradient(90deg, #FFBE04, #FF7D0D)',
          contentArr: [
            '无风险',
            '绿色自然外治疗法',
            '无副作用',
            '可无创更安全'
          ]
        },
        {
          icon: require('@/assets/home/advantage/adv_02.png'),
          title: '诊治高效',
          enTitle: '',
          background: 'background: linear-gradient(90deg, #98DBFF, #4C9DFF)',
          contentArr: [
            '诊断率高',
            '止痛消炎退热快',
            '止痒止泻止血快',
            '降压通便催产快'
          ]
        },
        {
          icon: require('@/assets/home/advantage/adv_03.png'),
          title: '作用持久',
          enTitle: '',
          background: 'background: linear-gradient(90deg, #C2A0FF, #BEB5FF, #7668FA);',
          contentArr: [
            '耳穴贴压可持续作用于穴位',
            '无需频繁奔波治疗'
          ]
        },
        {
          icon: require('@/assets/home/advantage/adv_04.png'),
          title: '操作简便',
          enTitle: '',
          background: 'background: linear-gradient(90deg, #C3EB93, #8AB622);',
          contentArr: [
            '不受气候、环境、场地等限制',
            '操作工具简单便携'
          ]
        },
        {
          icon: require('@/assets/home/advantage/adv_05.png'),
          title: '快速掌握',
          enTitle: '',
          background: 'background: linear-gradient(90deg, #8769B8, #603589);',
          contentArr: [
            '入门快',
            '易学易上手',
            '人人可操作'
          ]
        },
        {
          icon: require('@/assets/home/advantage/adv_06.png'),
          title: '主辅兼容',
          enTitle: '',
          background: 'background: linear-gradient(90deg, #E598F0, #CE54CF);',
          contentArr: [
            '耳穴高效病种可做为主治项目',
            '可做为其他疗法的辅助治疗项目',
            '中西医各科室,各疗法均可使用'
          ]
        },
        {
          icon: require('@/assets/home/advantage/adv_07.png'),
          title: '容易接受',
          enTitle: '',
          background: 'background: linear-gradient(90deg, #E9A2A2, #FFB69D, #DF3939);',
          contentArr: [
            '治疗无痛苦',
            '随时随地可操作',
            '无需特殊配合要求',
            '随治随走'
          ]
        },
        {
          icon: require('@/assets/home/advantage/adv_08.png'),
          title: '便于推广',
          enTitle: '',
          background: 'background: linear-gradient(90deg, #EEE585, #FFC12C);',
          contentArr: [
            '耳穴综合优势强',
            '适用于所有人群',
            '耳穴发展潜能大'
          ]
        },
      ],
      // *******************八大优势******************************** *//

      // *******************学员反馈******************************** *//
      feedbackInfoArr: [
        {
          icon: require('@/assets/home/feedback/index_xyfk_three@2x.png'),
          title: '累计付费学员',
          num: '5000+'
        },
        {
          icon: require('@/assets/home/feedback/index_xyfk_two@2x.png'),
          title: '累计授课时间',
          num: '20000+'
        },
        {
          icon: require('@/assets/home/feedback/index_xyfk_one@2x.png'),
          title: '累计在线用户',
          num: '60000+'
        },
      ],
      feedbackContentArr: [
        {
          img: require('@/assets/home/feedback/chat/avatar01.png'),
          content: '耳穴堂国内首款免费3D耳穴软件太棒太实用了！',
          top: 71,
          left: 38
        },
        {
          img: require('@/assets/home/feedback/chat/avatar02.png'),
          content: '说实话网课听过很\n多，耳穴堂讲的好',
          top: 221,
          left: 110
        },
        {
          img: require('@/assets/home/feedback/chat/avatar03.png'),
          content: '耳穴堂的服务非常好，信誉\n度非常高！',
          top: 342,
          left: 40
        },
        {
          img: require('@/assets/home/feedback/chat/avatar04.png'),
          content: '作为一个纯粹的耳穴人，李超老师教会了我们太多的临床经验，单单\n我们用这些临床经验治疗患者，为患者为国家节省了多少医疗开支！',
          top: 445,
          left: 142
        },
        {
          img: require('@/assets/home/feedback/chat/avatar05.png'),
          content: '我们从耳穴堂初级班走到高级班，学习李超老师的耳穴就是冲着李老\n师讲课掏心掏肺的劲！',
          top: 145,
          left: 435
        },
        {
          img: require('@/assets/home/feedback/chat/avatar06.png'),
          content: '感恩遇见。第一次付费上网课，遇到李超老师这么认真、负责、有耐心的\n人，真是当学生的一种幸运。感恩！',
          top: 231,
          left: 371
        },
        {
          img: require('@/assets/home/feedback/chat/avatar07.png'),
          content: '自从学了耳穴堂李超老师的高级班课程，边学边用，患者反馈效果杠杠滴，现在进了\n病房，一时半会儿都出不来。患者要贴，患者的七大姑八大姨也贴，隔壁床，隔壁床\n的陪护，也要贴。。。一天天的累懵的感脚。 感恩老师，“痛”但快乐着。哈哈。。。',
          top: 312,
          left: 565
        },
        // {
        //   img: require('@/assets/home/feedback/chat/avatar08.png'),
        //   content: '课程超级详细哦'
        // },
        {
          img: require('@/assets/home/feedback/chat/avatar09.png'),
          content: '感恩王正老师的孜孜传承，感谢耳穴堂的胸怀！感谢赵凯\n老师及团队的辛苦付出，感动同行们学习耳穴的热情，让\n我们为耳穴发扬光大携手前行！',
          top: 51,
          left: 747
        },
        {
          img: require('@/assets/home/feedback/chat/avatar10.png'),
          content: '我也是李超老师的追随者，临床经验超级丰富，期\n盼已久的经验穴课终于要实现了！',
          top: 422,
          left: 792
        },
        {
          img: require('@/assets/home/feedback/chat/emoticon03.png'),
          top: 52,
          left: 461
        },
        {
          img: require('@/assets/home/feedback/chat/emoticon02.png'),
          top: 312,
          left: 301
        },
        {
          img: require('@/assets/home/feedback/chat/emoticon01.png'),
          top: 172,
          left: 961
        },
      ],
      feedbackBg: require('@/assets/home/feedback/chat/chat_bg.png'),
      // *******************学员反馈******************************** *//

     // *******************视频******************************** *//
     playerOptions: {
            playbackRates: [], // 可选的播放速度
            autoplay: false, // 如果为true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 是否视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [{
              type: "video/mp4", // 类型
              src: 'https://static.cdn.erxueku.com/mp4/1%E5%85%AD%E5%88%86%E9%92%9F%E4%BA%86%E8%A7%A3%E4%BD%95%E4%B8%BA%E8%80%B3%E7%A9%B4%E7%96%97%E6%B3%95-%E5%8A%A0%E7%89%87%E5%A4%B4%E7%89%88.mp4' // url地址
            }],
            poster: require('@/assets/home/index_video@2x.png'), // 封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true, // 当前时间和持续时间的分隔符
              durationDisplay: true, // 显示持续时间
              remainingTimeDisplay: false, // 是否显示剩余时间功能
              fullscreenToggle: true // 是否显示全屏按钮
            }
          },
     // *******************视频******************************** *//

    // *******************弹框******************************** *//
    alertData: {},
    // 联系客服弹框数据
    contactsAlertData: {
      title1: '扫码咨询',
      title2: '请用微信扫码添加客服：耳穴堂明明',
      qrcodeUrl: require('@/assets/footer/qr_contact.jpeg'),
      title3: '请使用微信扫描二维码'
    },
    // 首次进入页面弹框数据
    fisrtAlertData: {
      title1: '扫码获取耳穴图',
      title2: '请用微信扫码添加客服：耳穴堂明明',
      qrcodeUrl: require('@/assets/home/qr_first.jpeg'),
      title3: ''
    },
    
    // *******************弹框******************************** *//


      
    };
  },
  mounted(){
    if (!sessionStorage.getItem("hasShowAlert")) {
      sessionStorage.setItem('hasShowAlert','1');
      this.showAlertAction(this.fisrtAlertData)
    }
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
    this.choiceTap(0)
  },
  methods: {
    handleScroll() {
       let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
       if (scrolltop < this.scrolltop) {
         this.isTop = false
       }else {
        scrolltop > 428 ? (this.isTop = true) : (this.isTop = false);
       }
      this.scrolltop = scrolltop
    },
    itemClick() {
      // this.$router.push({
      //   name: item.component,
      //   params: {
      //     title: item.name,
      //   },
      // });
    },
    menuTap(index) {
      this.selectedMenuIdx = index
      let toId = this.menuArr[this.selectedMenuIdx].eleId
       window.scrollTo({
          //滚动到元素位置
          //top: this.$refs[pos][0].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
          top: this.$refs[toId].getBoundingClientRect().top + window.scrollY - 68,//推荐使用。getBoundingClientRect 相对于当前视口的位置
          //top: 400//滚动到指定距离
          //top: 0,//滚动到顶部
          //top: document.documentElement.scrollHeight,//滚动到底部
          behavior: "smooth" // 平滑滚动
        });
    },
    // *******************服务体系******************************** *//
    choiceTap(index) {
      this.$refs.mySwiper.$swiper.slideToLoop(index, 1000, false);//切换到第一个slide，速度为1秒
    },
    // *******************服务体系******************************** *//

    // *******************弹框******************************** *//
    showAlertAction(alertData) {
      if (alertData) {
        this.alertData = alertData
        this.$refs.codeAlert.showSelf()
      }
    }
    // *******************弹框******************************** *//


  },
}

</script>

<style lang="less">
  .home-container {
    width: 100vw;
  }




  // *******************中部菜单******************************** */
  .menu-block {
    position: sticky;
    padding: 24px 0;
    background-color: #fff;
    width: 100vw;
    z-index: 1001;
    .menu-item{
      margin-right: 49px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
      line-height: 20px;
    }
    .menu-item.selected{
      color: #317BFF;
    }
  }
  // *******************中部菜单******************************** */

  // *******************视频区******************************** */
  .video-block {
    margin-bottom: 90px;
    .video-img {
      height: 675px;
      border-radius: 10px;
      overflow: hidden;
    }
  }
  .video-js .vjs-big-play-button {
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  // *******************视频区******************************** */

  // *******************服务体系******************************** */
  .title-view {
      .line {
        width: 21px;
        height: 3px;
        background: #E9E9E9;
      }
      .text {
        margin: 0 17px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #212121;
        line-height: 55px;
      }
    }

  .system-block{
    width: 100vw;
    background: #FBFBFB;
    padding: 70px 0;

    

    .content {
      margin-top: 48px;
      height: 600px;
      .item-img {
        width: 740px;
        height: 100%;
      }
      // 选项栏
      .choice-block {
        flex: 1;
        height: 100%;
        background-color: white;
        .choice-item {
          width: 434px;
          height: 87px;
          border-radius: 9px;
          &:hover {
            box-shadow: 0px 5px 5px 0px rgba(227, 236, 255, 0.55);
          }
          .choice-icon {
            width: 46px;
            height: 46px;
          }
          .desc-block {
            margin-left: 8px;
            .title1 {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #212121;
              line-height: 20px;
              text-align: left;
            }
            .title2 {
              margin-top: 10px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #888888;
              line-height: 20px;
            }
          }
        }
      }
    }
      .consulting-img {
        margin-top: 20px;
        height: 80px;
      }

      .more-ask {
        margin-top: 40px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        width: 262px;
        height: 60px;
        background: #317BFF;
        border-radius: 30px;
      }
  }
  // *******************服务体系******************************** */

  // *******************八大优势******************************** */
  .advantage-block {
    width: 100vw;
    padding: 70px 0 90px;
    background: #2E2E2E;
    .title-view {
      .line {
        background: #ffffff;
      }
      .text {
        color: #ffffff;
      }
    }

    .advant-img-list {
      margin-top: 50px;
      .advant-item {
        margin-bottom: 25px;
        width: 280px;
        height: 260px;
        background: #FFFFFF;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        text-decoration: none;
        &:hover {
          margin-top: -10px;
          -webkit-box-shadow: linear-gradient(295deg,#353638 0,#424345 100%);
          box-shadow: linear-gradient(295deg,#353638 0,#424345 100%);
        }
        .advant-half {
          width: 100%;
          box-sizing: border-box;
        }
        .advant-top {
          height: 108px;
          padding: 29px 11px 31px 24px;
          .title1 {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 24px;
          }
          .title2 {
            margin-top: 11px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 14px;
          }
          .advant-img {
            width: 54px;
            height: 54px;
          }
        }
        .advant-bottom {
          flex: 1;
          padding: 29px 26px 13px;
          .advant-bottom-half {
            // width: 50%;
          }
          .content-item {
            margin-top: 8px;
            width: 100%;
            text-align: left;
            font-size: 16px;
          }
        }
      }
    }
    
  }
  // *******************八大优势******************************** */

  // *******************学员反馈******************************** */
  .feedback-block {
    padding: 70px 0;
    background-color: white;
     .title-view {
      .line {
        background: #E9E9E9;
      }
      .text {
        color: #212121;
      }
    }

    .feedback-info {
      width: 100%;
      background: #F5F8FF;
      padding: 20px 0;
      .item {
        width: 30%;
        .icon {
          width: 56px;
          height: 56px;
        }
        .feedbackinfo-right {
          margin-left: 15px;
          .num {
            font-size: 30px;
            font-family: DIN;
            font-weight: bold;
            color: #212121;
            line-height: 20px;
          }
          .title {
            margin-top: 15px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #7AA2FF;
            line-height: 14px;
          }
        }
      }
    }

    .feedback-chat-block {
      margin-top: 14px;
      position: relative;
      height: 562px;        
      background-size: 1200px 562px;
      .chat-bg-img {
        width: 100%;
        height: 562px;
      }
      .content-block {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        .chat-item {
          position: absolute;
          .feedback-img {
            width: 30px;
            height: 30px;
          }
          .feedback-img.big {
            width: 72px;
            height: 72px;
          }
          .content {
            margin-left: 21px;
            background: #FFFFFF;
            border-radius: 8px;
            white-space: pre-line;
            padding: 15px;
            text-align: left;
            font-size: 14px;
            line-height: 20px;
            &:hover {
              background: #93DE57;
              transform:scale(1.06); 
            }
          }
        }
      }
    }

    .more {
      margin-top: 40px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #317BFF;
      line-height: 20px;
      width: 262px;
      height: 60px;
      border: 1px solid #317BFF;
      border-radius: 30px;
    }
  }
  // *******************学员反馈******************************** */


  

</style>