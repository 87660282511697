<template>
  <div class="com-container flex-row flex-center">
    <!-- 轮播图 -->
    <home-swiper :pageMenus="pageMenus"></home-swiper>

    <!-- 课堂按钮 -->
      <div class="menu-block flex-row flex-center" :style="isTop ? 'top:0px':'top:64px'">
        <div class="main-width flex-column flex-middle">
          <div v-for="menuItem,index in menuArr" :key="index" class="menu-item" :class="{'selected': index == selectedMenuIdx}" @click="menuTap(index)">{{menuItem.title}}</div>
          <div class="consulting right-view text-center" @click="showAlertAction(contactsAlertData)">立即咨询</div>
        </div>
      </div>
    <!-- 课堂按钮End -->

    <!-- 课程面向人群 -->
    <div class="face-block item-block main-width flex-row flex-center" :style="{backgroundImage: 'url(' + facekBg + ')'}" ref="face-block">
      <img :src="faceTitleImg" class="title-img" />
      <div class="content-block flex-column flex-between">
          <div v-for="faceItem,index in  faceArr" class="content-item flex-column" :key="index">
            <img :src="faceItem.img" class="icon" />
            <div class="face-right flex-row">
              <div class="face-item-title">{{faceItem.title}}</div>
              <div class="content-text">{{faceItem.content}}</div>
            </div>
          </div>
      </div>
    </div>
    <!-- 课程面向人群End -->

    <!-- 资深讲师 -->
    <div class="lecturer-block flex-row flex-center">
      <div class="item-block main-width flex-row flex-center">
        <img :src="lecturerTitleImg" class="title-img" />
        <div class="content-block flex-column flex-between">
            <div v-for="lecturerItem,index in  lecturerContentArr" class="content-item flex-column" :key="index">
              <img :src="lecturerItem.img" class="icon" />
              <div class="lecturer-right flex-row flex-left">
                <div class="lecturer-item-top flex-column flex-middle">
                    <div class="item-title">{{lecturerItem.name}}</div>
                    <div v-for="tagItem,tagIdx in lecturerItem.tags" :key="tagIdx" class="tag-text text-center">{{tagItem}}</div>
                </div>
                <div class="content-text">{{lecturerItem.detail}}</div>
                <div class="paper-title flex-column flex-middle">
                  <div>荣誉</div>
                  <div class="line-gray"></div>
                </div>
                <div class="papers-block flex-column flex-middle">
                  <div v-for="paperItem,paperIdx in lecturerItem.papers" :key="paperIdx" class="paper-text text-center">{{paperItem}}</div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- 资深讲师End -->

    <!-- 成长激活 -->
    <div class="grow-block item-block main-width flex-row flex-center"  :style="{backgroundImage: 'url(' + growBg + ')'}">
        <img :src="growTitleImg" class="title-img" />
        <div class="content-block flex-column flex-center">
          <div class="content-title-block flex-column flex-between">
              <div v-for="growItem,index in  growContentArr" class="tag-text text-center" :key="index" @mouseenter="growItemTap(index)">{{growItem.tagTitle}}</div>
          </div>
          <div class="content-sub-block flex-column flex-between">
            <div v-for="contentSubItem,contentSubIdx in growSubItemArr" :key="contentSubIdx" class="content-sub-item flex-column flex-top">
                <img :src="contentSubItem.img" class="grow-sub-img" />
                <div class="right-grow-sub-item flex-row flex-left">
                  <div class="grow-sub-title">{{contentSubItem.title}}</div>
                  <div class="grow-sub-block flex-row flex-left">
                    <div v-for="descItem,descIdx in contentSubItem.descArr" :key="descIdx" class="grow-sub-item">
                      <div class="title">{{descItem.title}}</div>
                      <div v-if="descItem.type == 1" class="desc-text">{{descItem.content}}</div>
                      <div v-if="descItem.type == 2" class="desc-arr flex-column flex-middle">
                        <div v-for="descType2,descType2Idx in descItem.content" :key="descType2Idx" class="desc-type-2">{{descType2}}</div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>

          <div class="more-btn" @click="showAlertAction(contactsAlertData)">点击领取学习路径图</div>
        </div>
    </div>
    <!-- 成长激活End -->

    <!-- 特色服务 -->
    <div class="service-block flex-row flex-center">
      <div class="item-block main-width flex-row flex-center">
        <img :src="serviceTitleImg" class="title-img" />
        <div class="content-block flex-column flex-between">
            <div v-for="serviceItem,index in  serviceContentArr" class="content-item flex-row flex-center" :key="index">
              <img :src="serviceItem.img" class="icon" />
              <div class="content-text">{{serviceItem.content}}</div>
            </div>
        </div>
      </div>
      <div class="more-btn" @click="showAlertAction(contactsAlertData)">点击了解详细学习服务</div>
    </div>
    <!-- 特色服务End -->


    <!-- 大纲列表 -->
      <div class="outline-block main-width flex-column flex-between" ref="outline-block">
        <!-- 左边 -->
        <div class="outline-half flex-row flex-center">
          <div v-for="item,index in outlineDataArr" :key="index" class="ouline-item-block" :class="{'show' : index%2 == 0}">
            <div v-if="index%2 == 0" class="outline-item flex-row flex-center">
                <div class="title"> {{item.title}} </div>
                <div class="desc">{{item.desc}}</div>
                <div v-for="chapterItem,idxChapter in item.chapterArr" :key="idxChapter" class="chapter-block">
                  <div class="chapter-title text-middle" @click="chapterTap(index,idxChapter)">{{idxChapter+1}}：{{chapterItem.chaperTitle}} <span class="arrow down right-view"></span></div>
                  <div :ref="'chapter' + index +idxChapter" class="chapter-item-container">
                    <div v-for="contentItem,idxContent in chapterItem.contentList" :key="idxContent" class="content-item" :class="{'first' : idxContent==0}">{{contentItem}}</div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <!-- 右边 -->
        <div class="outline-half flex-row flex-center">
          <div v-for="item,index in outlineDataArr" :key="index" class="ouline-item-block" :class="{'show' : index%2 != 0}">
            <div v-if="index%2 != 0" class="outline-item flex-row flex-center">
                <div class="title"> {{item.title}} </div>
                <div class="desc">{{item.desc}}</div>
                <div v-for="chapterItem,idxChapter in item.chapterArr" :key="idxChapter" class="chapter-block">
                  <div class="chapter-title text-middle" @click="chapterTap(index,idxChapter)">{{idxChapter+1}}：{{chapterItem.chaperTitle}} <span class="arrow down right-view"></span></div>
                  <div :ref="'chapter' + index +idxChapter" class="chapter-item-container">
                    <div v-for="contentItem,idxContent in chapterItem.contentList" :key="idxContent" class="content-item" :class="{'first' : idxContent==0}">{{contentItem}}</div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    <!-- 大纲列表End -->

        <!-- 弹框 -->
    <qr-code-alert ref="codeAlert" :alertData="alertData"></qr-code-alert>
    <!-- 弹框End -->
  </div>
</template>

<script>
import HomeSwiper from '../components/HomeSwiper.vue'
import QrCodeAlert from '../components/QrCodeAlert'

export default {
  name: 'JuniorCamp',
  components: {
    HomeSwiper,
    QrCodeAlert
  },
  metaInfo: {
    mata: [
      {
        name: 'keywords',
        content: '耳穴培训,耳穴挂图,耳穴位,耳朵穴位图,耳朵穴位,耳针图谱,耳穴,耳针,耳针疗法,耳穴疗法,耳穴医学,耳医学,贴耳穴,学耳穴,耳穴联盟,耳穴加盟,耳疗,耳穴堂,耳穴库,耳穴淘宝,耳穴视频,耳穴培训,耳穴图,耳穴网站'
      },
      {
        name: 'description',
        content: '耳穴库网是耳穴公益性学习网站提供一站式耳穴培训,是青岛耳穴堂公司推出的集耳穴定位、疾病配方、耳穴培训,耳穴工具、视频教程、经验分享、传承基地、论坛于一体的综合性网站，耳穴库网致力成为国内专业的耳穴互联网平台。'
      }
    ]
  },
  computed: {
    growSubItemArr() {
      return this.growContentArr[this.growSelectedIdx].contentArr
    }
  },
  data() {
    return {
      // *******************banner******************************** *//
      
      pageMenus: [
        {
          name: '名称4',
          icon: 'https://static.cdn.erxueku.com/exk/9.jpg',
          bgColor: '#e8f7ff',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNWVlMDVkMjYzYmY1ZV9pa3VxWUI3MiIsImNoYW5uZWxfaWQiOiIiLCJyZXNvdXJjZV9pZCI6IiJ9'
        },
        {
          name: '3d',
          icon: 'https://static.cdn.erxueku.com/exk/app3.png',
          bgColor: '#2f4469',
          url: 'https://mp.weixin.qq.com/s/3OMwW-6jEqPNbnwc_DrUDg'
        },
        {
          name: '商城',
          icon: 'https://static.cdn.erxueku.com/exk/sc4.png',
          bgColor: '#ecaf5c',
          url: 'https://shop45067782.m.youzan.com/v2/feature/iUUH2VMZTh?kdt_id=44875614&scan=3&from=singlemessage&sl=undefined&st=1&sf=wx_sm&is_share=1&share_cmpt=native_wechat&from_uuid=8f130a9d-4d9f-3ca7-07a8-a8981167a3ea'
        },
        {
          name: '小程序',
          icon: 'https://static.cdn.erxueku.com/exk/xcx5.png',
          bgColor: '#fdf5ec',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNjAzMWI1YTllNGIwZjE3NmFlZDMwMjg3IiwiY2hhbm5lbF9pZCI6IiIsInJlc291cmNlX2lkIjoiIn0',
          alertType: 1
        }
      ],
      // *******************banner******************************** *//

      // *******************公益课堂顶部数据******************************** *//
      classFeaturesArr: [
        {
          title: '大数据用户画像训练营',
          img: ''
        },
        {
          title: '大数据用户画像训练营大数据用户画像训练营',
          img: ''
        },
        {
          title: '大数据用户画像训练营',
          img: ''
        },
        {
          title: '大数据用户画像训练营',
          img: ''
        },
        {
          title: '大数据用户画像训练营',
          img: ''
        },
      ],
      // *******************公益课堂顶部数据******************************** *//

      // *******************面向人群******************************** *//
      facekBg: require('@/assets/juniorCamp/face_bg.png'),
      faceTitleImg: require('@/assets/juniorCamp/title01.png'),
      faceArr: [
        {
          title: '爱好耳穴',
          img: require('@/assets/juniorCamp/face01.png'),
          content: '喜爱耳穴疗法，想学习后用到日常生活中'
        },
        {
          title: '医护人员',
          img: require('@/assets/juniorCamp/face02.png'),
          content: '有志学习耳穴疗法的，相应用于临床'
        },
        {
          title: '技能提升',
          img: require('@/assets/juniorCamp/face03.png'),
          content: '使用过耳穴，应用中遇到疑惑、疗效欠佳基础不牢的'
        },
        {
          title: '健康服务',
          img: require('@/assets/juniorCamp/face04.png'),
          content: '中医大健康产业，掌握一项安全简便易学的技能'
        },
      ],
      // *******************面向人群******************************** *//

      // *******************资深讲师******************************** *//
      lecturerTitleImg: require('@/assets/juniorCamp/title02.png'),
      lecturerContentArr: [
        {
          img:  require('@/assets/juniorCamp/lichao.png'),
          name: '李超',
          tags: ['耳穴堂联合创始人'],
          detail: '国家中医药适宜技术职业化培训工程耳穴讲师、北京厚朴学堂耳穴讲师、王正教授耳穴诊治第一代传承人。\
发表三篇耳穴论文《中欧耳穴结合贴压治疗小儿腺样体肥大53例疗效观察》、《耳穴磁珠贴压治疗身材矮小》、《耳穴针刺祛眼袋39例疗效观察》。2016年获得耳穴工作特殊贡献奖和耳穴临床经验先进奖。教学中将国标分区画入真人耳朵，创作《24节气耳穴与养生》、《耳穴意象推刮术》课程。 2020年创作了《培元固本耳廓按摩操》、《宁心耳廓按摩操》、《耳廓意象驻颜按摩术》、2022年创作了《耳穴调理腺样体肥大》、2022年创作了《儿童身高管理课程》。',
          papers: ['北京厚朴学堂耳穴讲师','执业医师','国家中医药适宜技术职业化培训工程','首位耳穴讲师']
        },
        {
          img:  require('@/assets/juniorCamp/lizhichao.png'),
          name: '李志超',
          tags: ['耳穴堂讲师'],
          detail: '师承于国家名老中医王照中先生，跟随先生左右学习耳穴、针灸、点穴、按摩、医疗气功等中医非药物疗法。期间参加“耳穴泰斗”王正教授的高级耳穴传承班学习，“耳穴泰斗”朱丹教授学习跟诊，参加李超医师耳穴医学高级班，并跟诊李超医师深入学习耳穴临床应用。主讲课程：耳穴必备中医基础线上课。',
          papers: ['中国针灸协会会员','中医师承继承人','朱丹耳穴诊治第一代传承人','青岛市市南区老年大学中医健康讲师']
        }
      ],
      // *******************资深讲师******************************** *//

     // *******************成长激活******************************** *//
     growBg: require('@/assets/juniorCamp/grow_bg.png'),
     growTitleImg: require('@/assets/juniorCamp/title03.png'),
     growSelectedIdx: 0,
     growContentArr: [
       {
         tagTitle: '线上线下课程',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '线上课程',
             descArr: [
               {
                 title: '授课内容',
                 content: '以基础理论为主，学习不受时间场地限制，自由掌握学习进度。\n'+
                    '也可跟随每期初级学习营跟随学习进度。\n'+
                     '通过16课时，全面了解耳穴的起源与发展，治病原理与诊治方法。\n' +
                     '掌握国标耳穴93个穴位的分区定位，功能主治，贴压技巧，常见疾病贴压。\n',
                 type: 1
               },
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '线下课程',
             descArr: [
               {
                 title: '授课内容',
                 content: '国标93个穴位真人耳朵实操练习。线下实操两天时间，24人小班制。\n' +
                     '全部真人耳朵练习国标划线分区，与93个穴位贴压。\n' +
                     '一对一辅导检查考核，让理论结合实际，耳穴应用更准确。\n',
                 type: 1
               },
             ]
           }
         ]
       },
       {
         tagTitle: '耳穴师工具箱',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '线上工具',
             descArr: [
               {
                 title: '',
                 content: '配套教学与练习工具、讲义；课后疑难点解答，穴位配方查询，视频资料方便学习与快速掌握。',
                 type: 1
               }
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '耳穴工具',
             descArr: [
               {
                 title: '',
                 content: '耳朵分区空白练习图、国标耳穴分区练习图、耳穴贴压工具、课程讲义、3D耳穴软件、耳穴库、耳穴工具库、耳穴笔记练习本等',
                 type: 1
               },
             ]
           }
         ]
       },
       {
         tagTitle: '每日督促指导',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '每日督促指导',
             descArr: [
               {
                 title: '',
                 content: '按照学习营学习时间进度，进行每日督学、指导难点。',
                 type: 1
               },
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '督导教学',
             descArr: [
               {
                 title: '',
                 content: '每日提醒学习内容、提醒学习进度、打卡提醒、作业提醒，确保每位学员跟上学习进度。',
                 type: 1
               }
             ]
           }
         ]
       },
       {
         tagTitle: '课后作业专人批改',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '课后作业批改',
             descArr: [
               {
                 title: '',
                 content: '每节课后，针对知识点布置作业，助教批改',
                 type: 1
               }
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '一对一查阅',
             descArr: [
               {
                 title: '',
                 content: '对学习营每人的作业，进行逐一检查，及时纠正错误。',
                 type: 1
               },
             ]
           }
         ]
       },
       {
         tagTitle: '固定直播答疑',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '直播答疑',
             descArr: [
               {
                 title: '',
                 content: '针对疑点难点集中直播答疑。',
                 type: 1
               },
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '固定答疑',
             descArr: [
               {
                 title: '',
                 content: '根据每期学习营情况，安排固定直播答疑，跟营老师对疑难点再次细致讲解，确保每位学员掌握。',
                 type: 1
               }
             ]
           }
         ]
       },
       {
         tagTitle: '考试认证',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '课后测验',
             descArr: [
               {
                 title: '',
                 content: '结课后有线上考试测验，检测学习掌握情况。考试通过领取线上结课证书。',
                 type: 1
               },
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '线上考试',
             descArr: [
               {
                 title: '',
                 content: '课后6次检验练习，结课后进行考试。考试通过领取线上证书。可参加初级线下实操课程。',
                 type: 1
               },
              //  {
              //    title: '授课内容',
              //    content:['内容1','内容2'] ,
              //    type: 2
              //  },
             ]
           }
         ]
       }
     ],
     // *******************成长激活******************************** *//

     // *******************特色服务******************************** *//
     serviceTitleImg: require('@/assets/juniorCamp/title04.png'),
     serviceContentArr: [
       {
         img:require('@/assets/juniorCamp/serve01.png'),
         content:'名师定制职业生涯规划、毕业师兄现身传授经验、不定期邀请资深耳穴大家分享心得'
       },
       {
         img:require('@/assets/juniorCamp/serve02.png'),
         content:'课程大纲设计针对耳穴堂Lv1-Lv5,所有知识点结合实操，举一反三，触类旁通'
       },
       {
         img:require('@/assets/juniorCamp/serve03.png'),
         content:'耳穴堂首创真人耳朵画分区，站在实战角度掌握耳穴实操原理看，所有课程全部共享，实时在线协助学员解决疑难杂症'
       },
       {
         img:require('@/assets/juniorCamp/serve04.png'),
         content:'耳穴堂首创6S服务体系，保证学习的效果，提升耳穴技能晋级不是梦'
       },
       {
         img:require('@/assets/juniorCamp/serve05.png'),
         content:'高端耳穴技术资源圈让你不再是一个人在奋斗，定期邀请耳穴界精英、泰斗举办线上、线下分享、社群交流分享耳穴临床心得，让你耳穴技术、人脉资源双丰收'
       },
       {
         img:require('@/assets/juniorCamp/serve06.png'),
         content:'全国耳穴相关项目加盟指导合作，技术支持'
       },
     ],
     // *******************特色服务******************************** *//



      // *******************中部按钮******************************** *//
      isTop: false,//是否滑动菜单栏到顶部了
      selectedMenuIdx:0, //选中索引
      scrolltop: 0,//滑动距离顶部距离
      menuArr: [
        {
          title: '课程详情',
          eleId: 'face-block'
        },
        {
          title: '课程大纲',
          eleId: 'outline-block'
        }
      ],

      // *******************中部按钮******************************** *//

      // *******************大纲数据******************************** *//
      outlineSpreadInfo: {}, //大纲菜单展开信息 以第几章第几节为key
      outlineDataArr: [
        {
          title: '1、耳穴起源发展与治疗原理方法',
          desc: '通过16课时，全面了解耳穴的起源与发展，治病原理与诊治方法。' +
              '掌握国标耳穴93个穴位的分区定位，功能主治。' +
              '是初步拥有耳穴诊治基础的必备课程。',
          chapterArr: [
            {
              chaperTitle: '耳穴的定义',
              contentList: [
              ]
            },
            {
              chaperTitle: '耳穴的起源与发展',
              contentList: [
              ]
            },
            {
              chaperTitle: '耳穴国家标准的缘由与诞生',
              contentList: [
              ]
            },
              {
                  chaperTitle: '耳穴治疗的原理',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '耳穴治疗方法',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '耳穴疗法的优势与适应症',
                  contentList: [
                  ]
              },
          ]
        },
        {
          title: '2、解剖部位名称及记忆技巧',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '如何学习耳穴定位',
              contentList: [
              ]
            },
            {
              chaperTitle: '耳轮',
              contentList: [
              ]
            },
            {
              chaperTitle: '对耳轮',
              contentList: [
              ]
            },
              {
                  chaperTitle: '耳舟',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '三角窝',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '耳屏',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '对耳屏',
                  contentList: [
                  ]
              },
          ]
        },
        {
          title: '3、耳轮13穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '耳轮分区概述',
              contentList: [
              ]
            },
            {
              chaperTitle: '耳中穴',
              contentList: [
              ]
            },
            {
              chaperTitle: '直肠穴',
              contentList: [
              ]
            },
              {
                  chaperTitle: '尿道穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '外生殖器穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '肛门穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '耳尖穴',
                  contentList: [
                  ]
              },
          ]
        },
        {
          title: '4、耳舟6穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '如何记忆耳轮13穴',
              contentList: [
              ]
            },
            {
              chaperTitle: '耳舟分区概述',
              contentList: [
              ]
            },
            {
              chaperTitle: '指穴',
              contentList: [
              ]
            },
            {
              chaperTitle: '腕穴',
              contentList: [
              ]
            },
              {
                  chaperTitle: '肘穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '肩穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '锁骨穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '风溪穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '如何记忆耳舟6穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '耳舟望诊之鱼鳞癣',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '风溪穴的放血方法',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '为何耳穴平面图许多分界线是弧形',
                  contentList: [
                  ]
              },
          ]
        },
        {
          title: '5、对耳轮6穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '对耳轮分区概述',
              contentList: [
              ]
            },
            {
              chaperTitle: '髋穴',
              contentList: [
              ]
            },
            {
              chaperTitle: '膝穴',
              contentList: [
              ]
            },
              {
                  chaperTitle: '踝穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '跟穴、指穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '踝关节扭伤 跟穴取穴位置',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '坐骨神经穴和臀穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '对耳轮体分区',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '腹穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '腰骶椎穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '胸穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '胸椎穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '颈穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '颈椎穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '交感穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '对耳轮上脚不明显怎么找',
                  contentList: [
                  ]
              },
          ]
        },
        {
          title: '6、三角窝5穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '三角窝分区概述',
              contentList: [

              ]
            },
            {
              chaperTitle: '角窝上穴',
              contentList: [

              ]
            },
            {
              chaperTitle: '内生殖器穴',
              contentList: [

              ]
            },
              {
                  chaperTitle: '角窝中穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '神门穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '盆腔穴',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '如何记忆三角窝',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '三角窝望诊案例',
                  contentList: [
                  ]
              },
          ]
        },
          {
              title: '7、耳屏9穴、对耳屏8穴上',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '耳屏4区9穴概述',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '上屏穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '下屏穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '咽喉穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '内鼻穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '屏尖穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '肾上腺穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '外耳穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '外鼻穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '屏间前穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '如何记忆耳屏9穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳屏单峰如何确定屏尖穴和肾上腺穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳屏长毛是长寿还是肿瘤？',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对屏尖穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '脑干穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '缘中穴',
                      contentList: [
                      ]
                  },

              ]
          },
          {
              title: '8、对耳屏8穴下、耳垂8穴',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '对耳屏概述和额穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '颞穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '枕穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '皮质下穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '屏间后穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '如何记忆对耳屏8穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对耳屏望诊之头痛与内向示例',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对耳屏“两个尖”如何确定对屏尖穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对屏尖不明显怎么确定',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳垂7区8穴概述',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '牙穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '舌穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '颌穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '垂前穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '眼穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '内耳穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '扁桃体穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '面颊穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '如何记忆耳垂8穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '真人耳朵耳垂区域划分',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳穴望诊之扁桃体发炎与龋齿',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '9、ABCD四点、耳轮脚周围穴位',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '耳甲18区21穴概述',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: 'ABCD四点和耳轮脚周围分区',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '口穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '食道穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '贲门穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '胃穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '十二指肠穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '小肠穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '大肠穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '阑尾穴',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '10、耳甲艇穴位',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '耳甲艇如何分区概述',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '艇角穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '膀胱穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '肾穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '输尿管穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '艇中穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '胰胆穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '肝穴',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '11、耳甲腔穴位、耳背穴位',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '脾穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '心穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '气管穴',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '肺穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '三焦穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '内分泌穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '如何记忆耳甲21穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳背5区9穴概述',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳背五脏穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳背沟穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '上耳根穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳迷根穴',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '下耳根穴',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '12、取穴原则、治疗常见的反应、诊病入门',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '国标耳穴归类',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '耳穴取穴原则',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '耳穴治疗常见反应',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '耳穴治疗注意事项',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳穴诊病入门',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '13、标志线和贴压秘笈',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '耳轮内缘线',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '对耳轮脊线',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '耳舟凹沟线',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '对耳轮耳舟缘',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '三角窝凹陷处后缘 三角窝凹沟线',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对耳轮三角窝缘',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳甲折线',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对耳轮耳甲缘',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对耳屏耳甲缘',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '真人标志线',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳穴贴压秘笈',
                      contentList: [
                      ]
                  },

              ]
          },
          {
              title: '14、耳轮脚长短不一、疾病1',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '耳轮脚大小不一，如何来定',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '疾病篇-感冒',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '咳嗽',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '呕吐',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '腹泻',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '15、疾病2',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '便秘',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '失眠',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '扭伤',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '颈椎病',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '牙痛',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '头痛',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '高血压',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '扁桃体炎',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '低血压',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '荨麻疹',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '口腔溃疡',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '急性中耳炎',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '急性结膜炎',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '16、疾病3、耳穴防学、贴压难点',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '痛经',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '耳尖放血',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '耳穴放血专用笔',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '耳轮穴位贴压难点解析',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳舟穴位贴压难点解析',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对耳轮穴位贴压难点解析',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '三角窝穴位贴压难点解析',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳屏穴位贴压难点解析',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对耳屏、耳垂穴位贴压难点解析',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳甲穴位贴压难点解析',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳背穴位贴压难点解析',
                      contentList: [
                      ]
                  },

              ]
          },
          {
              title: '17、真人耳朵93个穴位贴压视频',
              desc: '',
              chapterArr: [
              ]
          },
      ],
      // *******************大纲数据******************************** *//


      // *******************弹框******************************** *//
      alertData: {},
      // 联系客服弹框数据
      contactsAlertData: {
        title1: '扫码咨询',
        title2: '请用微信扫码添加客服：耳穴堂明明',
        qrcodeUrl: require('@/assets/footer/qr_contact.jpeg'),
        title3: '请使用微信扫描二维码'
      },
      
      // *******************弹框******************************** *//

      
    }
  },
  mounted(){
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
       let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
       if (scrolltop < this.scrolltop) {
         this.isTop = false
       }else {
        scrolltop > 428 ? (this.isTop = true) : (this.isTop = false);
       }
      this.scrolltop = scrolltop
    },
    // *******************按钮事件******************************** *//
    menuTap(index) {
      this.selectedMenuIdx = index
      let toId = this.menuArr[this.selectedMenuIdx].eleId
       window.scrollTo({
          //滚动到元素位置
          //top: this.$refs[pos][0].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
          top: this.$refs[toId].getBoundingClientRect().top + window.scrollY - 68,//推荐使用。getBoundingClientRect 相对于当前视口的位置
          //top: 400//滚动到指定距离
          //top: 0,//滚动到顶部
          //top: document.documentElement.scrollHeight,//滚动到底部
          behavior: "smooth" // 平滑滚动
        });
    },
    // *******************按钮事件******************************** *//

    // *******************大纲相关******************************** *//
    chapterTap(index, idxChapter) {
      let chapterKey = 'chapter' + index +idxChapter
      console.log(this.$refs[chapterKey][0].style.display);
      if (this.$refs[chapterKey][0].style.display == '' || this.$refs[chapterKey][0].style.display == 'none') {
        this.$refs[chapterKey][0].style.display = 'block'
      }else {
        this.$refs[chapterKey][0].style.display = 'none'
      }
    },
    // *******************大纲相关******************************** *//
  
    // *******************成长激活******************************** *//
    growItemTap(index) {
      this.growSelectedIdx = index
    },
    // *******************成长激活******************************** *//


    // *******************弹框******************************** *//
    showAlertAction(alertData) {
      if (alertData) {
        this.alertData = alertData
        this.$refs.codeAlert.showSelf()
      }
    }
    // *******************弹框******************************** *//

    
  }
}
</script>

<style lang="less" scoped>
 .com-container  {
   width: 100vw;
 }
  // *******************标题******************************** */
    .item-block {
      .title-view {
        margin-top: 53px;
        position: relative;
        .text {
          z-index: 10;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 20px;
        }
        .left-img {
          position: absolute;
          left: -16px;
          top: -7px;
          width: 32px;
          height: 32px;
          z-index: -1;
        }
        .right-img {
          position: absolute;
          right: -27px;
          bottom: -20px;
          width: 46px;
          height: 50px;
          z-index: -1;
        }
    }
    }
  // *******************标题******************************** */

  // *******************中部菜单******************************** */
  .menu-block {
    position: sticky;
    padding: 24px 0;
    background-color: #fff;
    width: 100vw;
    z-index: 1001;
    border-bottom:  2px #EFEFEF solid;
    .menu-item{
      margin-right: 49px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
      line-height: 20px;
    }
    .menu-item.selected{
      color: #317BFF;
      font-weight: bold;
    }
    .consulting {
      width: 126px;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #FF7114;
      border-radius: 22px;
      color: #FF7114;
      &:hover {
        width: 126px;
        height: 44px;
        background: #FF7114;
        border-radius: 22px;
        color: #FFFFFF;
      }
    }
  }
  // *******************中部菜单******************************** */

  // *******************面向人群******************************** */
  .face-block {
    background-size: 100%;
    padding-bottom: 40px;
    .title-img {
      margin-top: 46px;
      width: 236px;
      height: 57px;
    }
    .content-block {
      margin-top: 50px;
      .content-item {
        width: 556px;
        margin-top: 50px;
        .icon {
          width: 44px;
          height: 44px;
        }
        .face-right {
          margin-left: 25px;
          flex: 1;
          .face-item-title {
            width: 100%;
            text-align: left;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #317BFF;
            line-height: 20px;
          }
          .content-text {
            margin-top: 20px;
            width: 100%;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #444444;
            line-height: 30px;
            text-align: left;
          }
        }
      }
    }
    }
  // *******************面向人群******************************** */

  // *******************资深讲师******************************** */
  .lecturer-block {
    width: 100vw;
    background: #F3F4F6;
    padding-bottom: 70px;
    .title-img {
      margin-top: 50px;
      width: 212px;
      height: 68px;
    }
    .content-block {
      margin-top: 67px;
      width: 100%;
      .content-item {
        width: 580px;
        .icon {
          width: 261px;
          height: 285px;
        }
        .lecturer-right {
          margin-left: -50px;
          width: 362px;
          .lecturer-item-top {
            margin-top: 37px;
            width: 100%;
            .item-title {
              margin-right: 23px;
              font-size: 28px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
              line-height: 20px;
            }
            .tag-text {
              margin-right: 10px;
              height: 24px;
              border: 1px solid #FF7114;
              border-radius: 12px;
              font-size: 14px;
              padding: 0 10px;
              color: #FF7114;
            }
          }
          .content-text {
            margin-top: 13px;
            width: 100%;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #444444;
            line-height: 26px;
            text-align: left;
          }
          .paper-title {
            width: 100%;
            margin-top: 22px;
            height: 14px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #317BFF;
            line-height: 20px;
            .line-gray {
              margin-left: 8px;
              flex: 1;
              height: 1px;
              background: #E9E9E9;
            }
          }
          .papers-block {
            margin-top: 15px;
            .paper-text {
              margin-right: 10px;
              margin-bottom: 9px;
              padding: 9px 12px;
              background: #E9E9E9;
              border-radius: 2px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #444444;
            }
          }
        }
      }
      
    }
  }
  // *******************资深讲师******************************** */

  // *******************成长激活******************************** */
  .grow-block {
    background-size: 100%;
    .title-img {
      margin-top: 50px;
      width: 530px;
      height: 68px;
    }
    .content-block {
      margin-top: 46px;
      width: 100%;
      .content-title-block {
        width: 100%;
        .tag-text {
          padding: 13px 33px;
          border: 1px solid #444444;
          border-radius: 23px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          &:hover {
          border: 1px solid #317BFF;
            color: #FFFFFF;
            background: #317BFF;
          }
        }
      }

      .content-sub-block {
        margin-top: 57px;
        width: 100%;
        text-align: left;
        .content-sub-item {
          width: 539px;
          .grow-sub-img {
            width: 56px;
            height: 56px;
          }
          .right-grow-sub-item {
            margin-left: 19px;
            flex: 1;
            .grow-sub-title {
              font-size: 22px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
              line-height: 20px;
            }
            .grow-sub-block {
              width: 100%;
              .grow-sub-item {
              width: 100%;
                margin-top: 24px;
                .title {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #000000;
                  line-height: 20px;
                }
                .desc-text {
                  margin-top: 10px;
                  width: 100%;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #000000;
                  line-height: 26px;
                }
                .desc-type-2 {
                  margin-top: 10px;
                  margin-right: 8px;
                  padding: 6px 12px;
                  border: 1px solid #666666;
                  border-radius: 2px;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #444444;
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
      .more-btn {
        margin: 120px 0 57px 0;
        padding: 17px 45px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        background: #317BFF;
        border-radius: 26px;
      }
    }

  }
  // *******************成长激活******************************** */

  // *******************特色服务******************************** */
  .service-block {
    width: 100vw;
    background: #F3F4F6;
    .item-block {
      .title-img {
        margin-top: 50px;
        width: 273px;
        height: 68px;
      }
      .content-block {
        width: 100%;
        .content-item {
          margin-top: 50px;
          width: 309px;
          .icon {
            width: 122px;
            height: 122px;
          }
          .content-text {
            margin-top: 26px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #000000;
            line-height: 26px;
          }
        }
      }
    }
      .more-btn {
        margin: 120px 0 57px 0;
        padding: 17px 45px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        background: #317BFF;
        border-radius: 26px;
      }
  }
  // *******************特色服务******************************** */



  // *******************大纲列表******************************** */
  .outline-block {
    padding: 50px 0;
    .outline-half {
        width: 578px;
    .ouline-item-block {
      width: 100%;
        box-sizing: border-box;
        background: linear-gradient(45deg, #E2E2E2 0%, #F9F9F9 100%);
        &.show {
          margin-top: 52px;
          padding: 24px;
        }
          .title {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #317BFF;
          line-height: 20px;
          width: 100%;
        }
        .desc {
          margin-top: 13px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #000000;
          line-height: 20px;
          width: 100%;
          text-align: left;
        }

        .chapter-block {
          margin-top: 24px;
          width: 100%;
          .chapter-title {
            width: 100%;
            box-sizing: border-box;
            padding:  10px 26px;
            background: #FFFFFF;
            border-radius: 4px;
            text-align: left;
          }
          .chapter-item-container {
            display: none;
          }
          .content-item {
            margin-top: 30px;
            width: 100%;
            padding: 0 26px;
            box-sizing: border-box;
            text-align: left;
            &.first {
              margin-top: 26px;
            }
            &:hover {
              color: #317BFF;
            }
          }
        }
      }
      
    }
  }
  .hidden {
    visibility: hidden;
  }
  // *******************大纲列表******************************** */

  

</style>