<template>
  <div class="com-container flex-row flex-center">
    <!-- 轮播图 -->
    <home-swiper :pageMenus="pageMenus"></home-swiper>

    <!-- 课堂按钮 -->
      <div class="menu-block flex-row flex-center" :style="isTop ? 'top:0px':'top:64px'">
        <div class="main-width flex-column flex-middle">
          <div v-for="menuItem,index in menuArr" :key="index" class="menu-item" :class="{'selected': index == selectedMenuIdx}" @click="menuTap(index)">{{menuItem.title}}</div>
          <div class="consulting right-view text-center" @click="showAlertAction(contactsAlertData)">立即咨询</div>
        </div>
      </div>
    <!-- 课堂按钮End -->

    <!-- 课程面向人群 -->
    <div class="face-block item-block main-width flex-row flex-center" :style="{backgroundImage: 'url(' + facekBg + ')'}" ref="face-block">
      <img :src="faceTitleImg" class="title-img" />
      <div class="content-block flex-column flex-between">
          <div v-for="faceItem,index in  faceArr" class="content-item flex-column" :key="index">
            <img :src="faceItem.img" class="icon" />
            <div class="face-right flex-row">
              <div class="face-item-title">{{faceItem.title}}</div>
              <div class="content-text">{{faceItem.content}}</div>
            </div>
          </div>
      </div>
    </div>
    <!-- 课程面向人群End -->

    <!-- 资深讲师 -->
    <div class="lecturer-block flex-row flex-center">
      <div class="item-block main-width flex-row flex-center">
        <img :src="lecturerTitleImg" class="title-img" />
        <div class="content-block flex-column  flex-center">
          <div v-for="teamItem,teamIdx in teamArr" :key="teamIdx" class="team-item flex-row flex-center flex-ellipsis">
            <img :src="teamItem.img" class="team-img" />
            <div class="name">{{teamItem.name}}</div>
            <div class="tag-text">{{teamItem.tag}}</div>
            <div class="desc flex-row flex-center" v-html="teamItem.desc"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 资深讲师End -->

    <!-- 成长激活 -->
    <div class="grow-block item-block main-width flex-row flex-center"  :style="{backgroundImage: 'url(' + growBg + ')'}">
        <img :src="growTitleImg" class="title-img" />
        <div class="content-block flex-column flex-center">
          <div class="content-title-block flex-column flex-between">
              <div v-for="growItem,index in  growContentArr" class="tag-text text-center" :key="index" @mouseenter="growItemTap(index)">{{growItem.tagTitle}}</div>
          </div>
          <div class="content-sub-block flex-column flex-between">
            <div v-for="contentSubItem,contentSubIdx in growSubItemArr" :key="contentSubIdx" class="content-sub-item flex-column flex-top">
                <img :src="contentSubItem.img" class="grow-sub-img" />
                <div class="right-grow-sub-item flex-row flex-left">
                  <div class="grow-sub-title">{{contentSubItem.title}}</div>
                  <div class="grow-sub-block flex-row flex-left">
                    <div v-for="descItem,descIdx in contentSubItem.descArr" :key="descIdx" class="grow-sub-item">
                      <div class="title">{{descItem.title}}</div>
                      <div v-if="descItem.type == 1" class="desc-text">{{descItem.content}}</div>
                      <div v-if="descItem.type == 2" class="desc-arr flex-column flex-middle">
                        <div v-for="descType2,descType2Idx in descItem.content" :key="descType2Idx" class="desc-type-2">{{descType2}}</div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>

          <div class="more-btn" @click="showAlertAction(contactsAlertData)">点击领取学习路径图</div>
        </div>
    </div>
    <!-- 成长激活End -->

    <!-- 特色服务 -->
    <div class="service-block flex-row flex-center">
      <div class="item-block main-width flex-row flex-center">
        <img :src="serviceTitleImg" class="title-img" />
        <div class="content-block flex-column flex-between">
            <div v-for="serviceItem,index in  serviceContentArr" class="content-item flex-row flex-center" :key="index">
              <img :src="serviceItem.img" class="icon" />
              <div class="content-text">{{serviceItem.content}}</div>
            </div>
        </div>
      </div>
      <div class="more-btn" @click="showAlertAction(contactsAlertData)">点击了解详细学习服务</div>
    </div>
    <!-- 特色服务End -->


    <!-- 大纲列表 -->
      <div class="outline-block main-width flex-column flex-between" ref="outline-block">
        <!-- 左边 -->
        <div class="outline-half flex-row flex-center">
          <div v-for="item,index in outlineDataArr" :key="index" class="ouline-item-block" :class="{'show' : index%2 == 0}">
            <div v-if="index%2 == 0" class="outline-item flex-row flex-center">
                <div class="title"> {{item.title}} </div>
                <div class="desc">{{item.desc}}</div>
                <div v-for="chapterItem,idxChapter in item.chapterArr" :key="idxChapter" class="chapter-block">
                  <div class="chapter-title text-middle" @click="chapterTap(index,idxChapter)">{{idxChapter+1}}：{{chapterItem.chaperTitle}} <span class="arrow down right-view"></span></div>
                  <div :ref="'chapter' + index +idxChapter" class="chapter-item-container">
                    <div v-for="contentItem,idxContent in chapterItem.contentList" :key="idxContent" class="content-item" :class="{'first' : idxContent==0}">{{contentItem}}</div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <!-- 右边 -->
        <div class="outline-half flex-row flex-center">
          <div v-for="item,index in outlineDataArr" :key="index" class="ouline-item-block" :class="{'show' : index%2 != 0}">
            <div v-if="index%2 != 0" class="outline-item flex-row flex-center">
                <div class="title"> {{item.title}} </div>
                <div class="desc">{{item.desc}}</div>
                <div v-for="chapterItem,idxChapter in item.chapterArr" :key="idxChapter" class="chapter-block">
                  <div class="chapter-title text-middle" @click="chapterTap(index,idxChapter)">{{idxChapter+1}}：{{chapterItem.chaperTitle}} <span class="arrow down right-view"></span></div>
                  <div :ref="'chapter' + index +idxChapter" class="chapter-item-container">
                    <div v-for="contentItem,idxContent in chapterItem.contentList" :key="idxContent" class="content-item" :class="{'first' : idxContent==0}">{{contentItem}}</div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    <!-- 大纲列表End -->

        <!-- 弹框 -->
    <qr-code-alert ref="codeAlert" :alertData="alertData"></qr-code-alert>
    <!-- 弹框End -->
  </div>
</template>

<script>
import HomeSwiper from '../components/HomeSwiper.vue'
import QrCodeAlert from '../components/QrCodeAlert'

export default {
  name: 'SeniorCamp',
  components: {
    HomeSwiper,
    QrCodeAlert
  },
  metaInfo: {
    mata: [
      {
        name: 'keywords',
        content: '耳穴培训,耳穴挂图,耳穴位,耳朵穴位图,耳朵穴位,耳针图谱,耳穴,耳针,耳针疗法,耳穴疗法,耳穴医学,耳医学,贴耳穴,学耳穴,耳穴联盟,耳穴加盟,耳疗,耳穴堂,耳穴库,耳穴淘宝,耳穴视频,耳穴培训,耳穴图,耳穴网站'
      },
      {
        name: 'description',
        content: '耳穴库网是耳穴公益性学习网站提供一站式耳穴培训,是青岛耳穴堂公司推出的集耳穴定位、疾病配方、耳穴培训,耳穴工具、视频教程、经验分享、传承基地、论坛于一体的综合性网站，耳穴库网致力成为国内专业的耳穴互联网平台。'
      }
    ]
  },
  computed: {
    growSubItemArr() {
      return this.growContentArr[this.growSelectedIdx].contentArr
    }
  },
  data() {
    return {

    // *******************banner******************************** *//
      
      pageMenus: [
        {
          name: '名称4',
          icon: 'https://static.cdn.erxueku.com/exk/g2.png',
          bgColor: '#eeebe2',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNjAzMWI1YTllNGIwZjE3NmFlZDMwMjg3IiwiY2hhbm5lbF9pZCI6IiIsInJlc291cmNlX2lkIjoiIn0'
        },
        {
          name: '3d',
          icon: 'https://static.cdn.erxueku.com/exk/app3.png',
          bgColor: '#2f4469',
          url: 'https://mp.weixin.qq.com/s/3OMwW-6jEqPNbnwc_DrUDg'
        },
        {
          name: '商城',
          icon: 'https://static.cdn.erxueku.com/exk/sc4.png',
          bgColor: '#ecaf5c',
          url: 'https://shop45067782.m.youzan.com/v2/feature/iUUH2VMZTh?kdt_id=44875614&scan=3&from=singlemessage&sl=undefined&st=1&sf=wx_sm&is_share=1&share_cmpt=native_wechat&from_uuid=8f130a9d-4d9f-3ca7-07a8-a8981167a3ea'
        },
        {
          name: '小程序',
          icon: 'https://static.cdn.erxueku.com/exk/xcx5.png',
          bgColor: '#fdf5ec',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNjAzMWI1YTllNGIwZjE3NmFlZDMwMjg3IiwiY2hhbm5lbF9pZCI6IiIsInJlc291cmNlX2lkIjoiIn0',
          alertType: 1
        }
      ],
      // *******************banner******************************** *//

      // *******************公益课堂顶部数据******************************** *//
      classFeaturesArr: [
        {
          title: '大数据用户画像训练营',
          img: ''
        },
        {
          title: '大数据用户画像训练营大数据用户画像训练营',
          img: ''
        },
        {
          title: '大数据用户画像训练营',
          img: ''
        },
        {
          title: '大数据用户画像训练营',
          img: ''
        },
        {
          title: '大数据用户画像训练营',
          img: ''
        },
      ],
      // *******************公益课堂顶部数据******************************** *//

      // *******************面向人群******************************** *//
      facekBg: require('@/assets/juniorCamp/face_bg.png'),
      faceTitleImg: require('@/assets/juniorCamp/title01.png'),
      faceArr: [
        {
          title: '拓展耳穴知识',
          img: require('@/assets/juniorCamp/face01.png'),
          content: '有耳穴国标基础或临床，想学习更多经验耳穴使用'
        },
        {
          title: '提高诊治疗效',
          img: require('@/assets/juniorCamp/face02.png'),
          content: '有一定临床使用经验，诊治遇到一定瓶颈，想深入学习耳穴诊治'
        },
        {
          title: '增加治疗项目',
          img: require('@/assets/juniorCamp/face03.png'),
          content: '想扩展耳穴诊治的病种，扩大诊治人群'
        },
        {
          title: '深耕耳穴专业',
          img: require('@/assets/juniorCamp/face04.png'),
          content: '有志成为高级耳穴师，做耳穴行业中的高精尖技术人士'
        },
      ],
      // *******************面向人群******************************** *//

      // *******************资深讲师******************************** *//
      lecturerTitleImg: require('@/assets/juniorCamp/title02.png'),
      lecturerContentArr: [
        {
          img:  require('@/assets/juniorCamp/teacher01.png'),
          name: '李超',
          tags: ['耳穴堂联合创始人'],
          detail: '发表全国首创三篇论文《中欧耳穴结合贴压治疗小儿腺样体肥大53例疗效观察》、《耳穴磁珠贴压治疗身材矮小》、《耳穴针刺祛眼袋39例疗效观察》。成为研究耳穴治疗儿童腺样体肥大专家，2016年获得耳穴工作特殊贡献奖和耳穴临床经验先进奖。教学中首创真人耳朵画国标分区，首创《24节气耳穴与养生》、《耳穴意象推刮术》课程。 2020年首创了《培元固本耳廓按摩操》、《宁心耳廓按摩操》、《耳廓意象驻颜按摩术》。',
          papers: ['北京厚朴学堂耳穴讲师','执业医师','国家中医药适宜技术职业化培训工程','首位耳穴讲师']
        }
      ],
      teamArr: [
      {
        img: require('@/assets/aboutUS/team/lichao.png'),
        name: '李超',
        tag: '耳穴堂联合创始人',
        desc: '<div>国家中医药适宜技术职业化培训工程耳穴讲师、北京厚朴学堂耳穴讲师、王正教授耳穴诊治第一代传承人。<br />\
              &emsp;&emsp;发表三篇耳穴论文《中欧耳穴结合贴压治疗小儿腺样体肥大53例疗效观察》、《耳穴磁珠贴压治疗身材矮小》、《耳穴针刺祛眼袋39例疗效观察》。2016年获得耳穴工作特殊贡献奖和耳穴临床经验先进奖。教学中将国标分区画入真人耳朵，创作《24节气耳穴与养生》、《耳穴意象推刮术》课程。 2020年创作了《培元固本耳廓按摩操》、《宁心耳廓按摩操》、《耳廓意象驻颜按摩术》、2022年创作了《耳穴调理腺样体肥大》、2022年创作了《儿童身高管理课程》。</div>\
        '
      }],
      // *******************资深讲师******************************** *//

     // *******************成长激活******************************** *//
     growBg: require('@/assets/juniorCamp/grow_bg.png'),
     growTitleImg: require('@/assets/juniorCamp/title03_senior.png'),
     growSelectedIdx: 0,
     growContentArr: [
       {
         tagTitle: '线上线下课程',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '线上课程',
             descArr: [
               {
                 title: '授课内容',
                 content: '以基础理论为主，学习不受时间场地限制，自由掌握学习进度。也可跟随每期高级学习营跟随学习进度。\
                 通过10课时，掌握116个常用经验耳穴定位，功能主治，贴压技巧，常见疾病治疗取穴思路。',
                 type: 1
               },
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '线下课程',
             descArr: [
               {
                 title: '授课内容',
                 content: '116个经验耳穴位真人耳朵实操练习\n' +
                     '线下实操3天时间，小班制授课。\
                    全部真人耳朵练习画经验穴点，与116个穴位贴压。\
                    一对一辅导检查考核，让理论结合实际，穴位应用更精准。\n',
                 type: 1
               },
             ]
           }
         ]
       },
       {
         tagTitle: '耳穴师工具箱',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '线上工具',
             descArr: [
               {
                 title: '',
                 content: '配套教学与练习工具、讲义；课后疑难点解答，穴位配方查询，视频资料方便学习与快速掌握。',
                 type: 1
               }
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '耳穴工具',
             descArr: [
               {
                 title: '',
                 content: '耳朵分区空白练习图、国标耳穴分区练习图、耳穴贴压工具、课程讲义、3D耳穴软件、耳穴库、耳穴工具库、耳穴笔记练习本等',
                 type: 1
               },
             ]
           }
         ]
       },
       {
         tagTitle: '每日督促指导',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '每日督促指导',
             descArr: [
               {
                 title: '',
                 content: '按照学习营学习时间进度，进行每日督学、指导难点。',
                 type: 1
               },
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '督导教学',
             descArr: [
               {
                 title: '',
                 content: '每日提醒学习内容、提醒学习进度、打卡提醒、作业提醒，确保每位学员跟上学习进度。',
                 type: 1
               }
             ]
           }
         ]
       },
       {
         tagTitle: '课后作业专人批改',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '课后作业批改',
             descArr: [
               {
                 title: '',
                 content: '每节课后，针对知识点布置作业，助教批改',
                 type: 1
               }
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '一对一查阅',
             descArr: [
               {
                 title: '',
                 content: '对学习营每人的作业，进行逐一检查，及时纠正错误。',
                 type: 1
               },
             ]
           }
         ]
       },
       {
         tagTitle: '固定直播答疑',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '直播答疑',
             descArr: [
               {
                 title: '',
                 content: '针对疑点难点集中直播答疑。',
                 type: 1
               },
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '固定答疑',
             descArr: [
               {
                 title: '',
                 content: '根据每期学习营情况，安排固定直播答疑，跟营老师对疑难点再次细致讲解，确保每位学员掌握。',
                 type: 1
               }
             ]
           }
         ]
       },
       {
         tagTitle: '考试认证',
         contentArr: [
           {
             img: require('@/assets/juniorCamp/grow01.png'),
             title: '课后测验',
             descArr: [
               {
                 title: '',
                 content: '结课后有线上考试测验，检测学习掌握情况。考试通过领取线上结课证书。',
                 type: 1
               },
             ]
           },
           {
             img: require('@/assets/juniorCamp/grow02.png'),
             title: '线上考试',
             descArr: [
               {
                 title: '',
                 content: '课后5次检验练习，结课后进行考试。考试通过领取线上证书。可参加初级线下实操课程。',
                 type: 1
               },
              //  {
              //    title: '授课内容',
              //    content:['内容1','内容2'] ,
              //    type: 2
              //  },
             ]
           }
         ]
       }
     ],
     // *******************成长激活******************************** *//

     // *******************特色服务******************************** *//
     serviceTitleImg: require('@/assets/juniorCamp/title04.png'),
     serviceContentArr: [
       {
         img:require('@/assets/juniorCamp/serve01.png'),
         content:'名师定制职业生涯规划、毕业师兄现身传授经验、不定期邀请资深耳穴大家分享心得'
       },
       {
         img:require('@/assets/juniorCamp/serve02.png'),
         content:'课程大纲设计针对耳穴堂Lv1-Lv5,所有知识点结合实操，举一反三，触类旁通'
       },
       {
         img:require('@/assets/juniorCamp/serve03.png'),
         content:'耳穴堂首创真人耳朵画分区，站在实战角度掌握耳穴实操原理看，所有课程全部共享，实时在线协助学员解决疑难杂症'
       },
       {
         img:require('@/assets/juniorCamp/serve04.png'),
         content:'耳穴堂首创6S服务体系，保证学习的效果，提升耳穴技能晋级不是梦'
       },
       {
         img:require('@/assets/juniorCamp/serve05.png'),
         content:'高端耳穴技术资源圈让你不再是一个人在奋斗，定期邀请耳穴界精英、泰斗举办线上、线下分享、社群交流分享耳穴临床心得，让你耳穴技术、人脉资源双丰收'
       },
       {
         img:require('@/assets/juniorCamp/serve06.png'),
         content:'全国耳穴相关项目加盟指导合作，技术支持'
       },
     ],
     // *******************特色服务******************************** *//



      // *******************中部按钮******************************** *//
      isTop: false,//是否滑动菜单栏到顶部了
      selectedMenuIdx:0, //选中索引
      scrolltop: 0,//滑动距离顶部距离
      menuArr: [
        {
          title: '课程详情',
          eleId: 'face-block'
        },
        {
          title: '课程大纲',
          eleId: 'outline-block'
        }
      ],

      // *******************中部按钮******************************** *//

      // *******************大纲数据******************************** *//
      outlineSpreadInfo: {}, //大纲菜单展开信息 以第几章第几节为key
      outlineDataArr: [
        {
          title: '1、耳轮12穴',
          desc: '10节视频课学习常用高效的116个经验耳穴，细分内容详细讲解，系统学习掌握经验耳穴的定位、应用重点，讲解部分经验耳穴诊断与疾病治疗速效方法。提高耳穴诊治效果。',
          chapterArr: [
            {
              chaperTitle: '耳穴堂教学体系视频',
              contentList: [
              ]
            },
            {
              chaperTitle: '前言视频',
              contentList: [
              ]
            },
            {
              chaperTitle: '主要参考书籍视频',
              contentList: [
              ]
            },
              {
                  chaperTitle: '膈穴讲解视频',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '神经官能点讲解视频',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '支点讲解视频',
                  contentList: [
                  ]
              },
            {
              chaperTitle: '睾丸2讲解视频',
              contentList: [
              ]
            },
            {
              chaperTitle: '止血点讲解视频',
              contentList: [
              ]
            },
            {
              chaperTitle: '感冒点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '扁桃体1、2、3讲解视频',
              contentList: [
              ]
            },
            {
              chaperTitle: '枕小神经点讲解视频',
              contentList: [
              ]
            },
            {
              chaperTitle: '肿瘤特异区1讲解视频',
              contentList: [
              ]
            },
            {
              chaperTitle: '肿瘤特异区2讲解视频',
              contentList: [
              ]
            },
            {
              chaperTitle: '耳轮穴位总结视频',
              contentList: [
              ]
            },
          ]
        },
        {
          title: '2、对耳轮11穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '易错内容',
              contentList: [
              ]
            },
            {
              chaperTitle: '耳轮穴位复习',
              contentList: [
              ]
            },
            {
              chaperTitle: '肩背讲解',
              contentList: [
              ]
            },
              {
                  chaperTitle: '甲状腺1讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '胁肋讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '乳腺讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '腰肌讲解',
                  contentList: [
                  ]
              },
            {
              chaperTitle: '腰痛点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '尾椎讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '热穴讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '腘窝讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '膝点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '腓肠肌点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '对耳轮穴位总结',
              contentList: [
              ]
            },
          ]
        },
        {
          title: '3、耳舟5穴&三角窝10穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '易错的问题',
              contentList: [
              ]
            },
            {
              chaperTitle: '复习对耳轮穴位',
              contentList: [
              ]
            },
            {
              chaperTitle: '腋下穴讲解',
              contentList: [
              ]
            },
              {
                  chaperTitle: '肾炎点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '风湿线讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '耳大神经点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '速听点讲解',
                  contentList: [
                  ]
              },
            {
              chaperTitle: '耳舟穴位总结',
              contentList: [
              ]
            },
            {
              chaperTitle: '子宫讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '盆腔点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '生殖线讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '咳喘点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '降压点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '头昏点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '神门点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '神志线讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '股关点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '便秘点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '三角窝穴位总结',
              contentList: [
              ]
            },
          ]
        },
        {
          title: '4、耳甲艇9穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '易错的问题',
              contentList: [
              ]
            },
            {
              chaperTitle: '耳舟三角窝穴位复习',
              contentList: [
              ]
            },
            {
              chaperTitle: '下垂点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '下焦（少腹）讲解',
              contentList: [
              ]
            },
              {
                  chaperTitle: '结肠讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '胆道讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '胰腺点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '腹水点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '降糖1讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '酒醉点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '腹胀区讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '耳甲艇穴位总结',
                  contentList: [
                  ]
              },
          ]
        },
        {
          title: '5、耳甲腔12穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '易错的问题',
              contentList: [
              ]
            },
            {
              chaperTitle: '耳甲艇穴位复习',
              contentList: [
              ]
            },
            {
              chaperTitle: '咽喉点讲解',
              contentList: [
              ]
            },
              {
                  chaperTitle: '疲消点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '降糖2讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '催眠点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '降糖3讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '下肺点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '气管点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '支气管点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '脾点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '肌松点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '新眼点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '心神穴讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '耳甲腔穴位总结',
                  contentList: [
                  ]
              },

          ]
        },
        {
          title: '6、耳屏6穴&对耳屏外7穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '耳甲腔穴位复习',
              contentList: [

              ]
            },
            {
              chaperTitle: '渴点讲解',
              contentList: [

              ]
            },
            {
              chaperTitle: '饥点讲解',
              contentList: [

              ]
            },
              {
                  chaperTitle: '心脏点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '高血压点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '激素点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '鼻咽点讲解',
                  contentList: [
                  ]
              },
              {
                  chaperTitle: '耳屏穴位总结',
                  contentList: [
                  ]
              },
            {
              chaperTitle: '镇咳讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '卵巢1讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '促性腺激素点讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '平喘讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '晕区讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '顶讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '神经衰弱区讲解',
              contentList: [
              ]
            },
            {
              chaperTitle: '对耳屏穴位总结',
              contentList: [
              ]
            },
          ]
        },
          {
              title: '7、对耳屏内11穴',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '耳屏•对耳屏穴位复习',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '丘脑讲解',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '兴奋点讲解',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '睾丸1讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '癫痫点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '心皮讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '神皮讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '消皮讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '垂体讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '脑点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '牙痛点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '对耳屏内侧面穴位总结',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '8、耳垂10穴',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '真人耳廓•神经衰弱区讲解',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '对耳屏内侧面穴位复习',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '升压点讲解',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '拔牙麻醉点1讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '拔牙麻醉点2讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '神经衰弱点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '嗅觉中枢讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '甲状腺2讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '身心点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '低血压沟讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '冠心沟讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳鸣沟讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳垂穴位总结讲解',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '9、耳背8穴',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '耳垂穴位复习',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '阳维讲解',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '背腘窝讲解',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '失眠点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '多梦区讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '背耳大神经点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '聪明点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '睡眠深沉点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '快活点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '耳背穴位总结',
                      contentList: [
                      ]
                  },
              ]
          },
          {
              title: '10、其它15穴',
              desc: '',
              chapterArr: [
                  {
                      chaperTitle: '复习耳背穴位',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '健脾讲解',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '牙痛奇穴讲解',
                      contentList: [

                      ]
                  },
                  {
                      chaperTitle: '鼻眼净讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '声带讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '防近点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '明亮点讲解',
                      contentList: [
                      ]
                  },
                  {
                      chaperTitle: '唇讲解',
                      contentList: [
                      ]
                  },
                {
                  chaperTitle: '阴囊讲解',
                  contentList: [
                  ]
                },
                {
                  chaperTitle: '孕线讲解',
                  contentList: [
                  ]
                },
                {
                  chaperTitle: '戒烟一区讲解',
                  contentList: [
                  ]
                },
                {
                  chaperTitle: '戒烟二区讲解',
                  contentList: [
                  ]
                },
                {
                  chaperTitle: '总结戒烟',
                  contentList: [
                  ]
                },
                {
                  chaperTitle: '眼睑讲解',
                  contentList: [
                  ]
                },
                {
                  chaperTitle: '胸腺讲解',
                  contentList: [
                  ]
                },
                {
                  chaperTitle: '腭扁桃腺',
                  contentList: [
                  ]
                },
                {
                  chaperTitle: '咽扁桃腺讲解',
                  contentList: [
                  ]
                },
              ]
          },
          {
              title: '11、116个经验耳穴真人耳朵贴压视频',
              desc: '',
              chapterArr: [
              ]
          },
      ],
      // *******************大纲数据******************************** *//


      // *******************弹框******************************** *//
      alertData: {},
      // 联系客服弹框数据
      contactsAlertData: {
        title1: '扫码咨询',
        title2: '请用微信扫码添加客服：耳穴堂明明',
        qrcodeUrl: require('@/assets/footer/qr_contact.jpeg'),
        title3: '请使用微信扫描二维码'
      },
      
      // *******************弹框******************************** *//

      
    }
  },
  mounted(){
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
       let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
       if (scrolltop < this.scrolltop) {
         this.isTop = false
       }else {
        scrolltop > 428 ? (this.isTop = true) : (this.isTop = false);
       }
      this.scrolltop = scrolltop
    },
    // *******************按钮事件******************************** *//
    menuTap(index) {
      this.selectedMenuIdx = index
      let toId = this.menuArr[this.selectedMenuIdx].eleId
       window.scrollTo({
          //滚动到元素位置
          //top: this.$refs[pos][0].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
          top: this.$refs[toId].getBoundingClientRect().top + window.scrollY - 68,//推荐使用。getBoundingClientRect 相对于当前视口的位置
          //top: 400//滚动到指定距离
          //top: 0,//滚动到顶部
          //top: document.documentElement.scrollHeight,//滚动到底部
          behavior: "smooth" // 平滑滚动
        });
    },
    // *******************按钮事件******************************** *//

    // *******************大纲相关******************************** *//
    chapterTap(index, idxChapter) {
      let chapterKey = 'chapter' + index +idxChapter
      console.log(this.$refs[chapterKey][0].style.display);
      if (this.$refs[chapterKey][0].style.display == '' || this.$refs[chapterKey][0].style.display == 'none') {
        this.$refs[chapterKey][0].style.display = 'block'
      }else {
        this.$refs[chapterKey][0].style.display = 'none'
      }
    },
    // *******************大纲相关******************************** *//
  
    // *******************成长激活******************************** *//
    growItemTap(index) {
      this.growSelectedIdx = index
    },
    // *******************成长激活******************************** *//


    // *******************弹框******************************** *//
    showAlertAction(alertData) {
      if (alertData) {
        this.alertData = alertData
        this.$refs.codeAlert.showSelf()
      }
    }
    // *******************弹框******************************** *//

    
  }
}
</script>

<style lang="less" scoped>
 .com-container  {
   width: 100vw;
 }
  // *******************标题******************************** */
    .item-block {
      .title-view {
        margin-top: 53px;
        position: relative;
        .text {
          z-index: 10;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 20px;
        }
        .left-img {
          position: absolute;
          left: -16px;
          top: -7px;
          width: 32px;
          height: 32px;
          z-index: -1;
        }
        .right-img {
          position: absolute;
          right: -27px;
          bottom: -20px;
          width: 46px;
          height: 50px;
          z-index: -1;
        }
    }
    }
  // *******************标题******************************** */

  // *******************中部菜单******************************** */
  .menu-block {
    position: sticky;
    padding: 24px 0;
    background-color: #fff;
    width: 100vw;
    z-index: 1001;
    border-bottom:  2px #EFEFEF solid;
    .menu-item{
      margin-right: 49px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
      line-height: 20px;
    }
    .menu-item.selected{
      color: #317BFF;
      font-weight: bold;
    }
    .consulting {
      width: 126px;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #FF7114;
      border-radius: 22px;
      color: #FF7114;
      &:hover {
        width: 126px;
        height: 44px;
        background: #FF7114;
        border-radius: 22px;
        color: #FFFFFF;
      }
    }
  }
  // *******************中部菜单******************************** */

  // *******************面向人群******************************** */
  .face-block {
    background-size: 100%;
    padding-bottom: 40px;
    .title-img {
      margin-top: 46px;
      width: 236px;
      height: 57px;
    }
    .content-block {
      margin-top: 50px;
      .content-item {
        width: 556px;
        margin-top: 50px;
        .icon {
          width: 44px;
          height: 44px;
        }
        .face-right {
          margin-left: 25px;
          flex: 1;
          .face-item-title {
            width: 100%;
            text-align: left;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #317BFF;
            line-height: 20px;
          }
          .content-text {
            margin-top: 20px;
            width: 100%;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #444444;
            line-height: 30px;
            text-align: left;
          }
        }
      }
    }
    }
  // *******************面向人群******************************** */

  // *******************资深讲师******************************** */
  .lecturer-block {
    width: 100vw;
    background: #F3F4F6;
    padding-bottom: 70px;
    .title-img {
      margin-top: 50px;
      width: 212px;
      height: 68px;
    }
    .content-block {
      margin-top: 25px;
      width: 100%;
      .team-item {
        width: 33%;
        padding: 58px 30px;
        box-sizing: border-box;
        // height: 850px;
        overflow: hidden;
        
        // transition: all 20s;
        &:hover {
          transition: all .6s;
          margin-top: -10px;
          background: rgba(255,255,255,.4);
          -webkit-box-shadow: 0 3px 24px rgba(0,0,0,.15);
          box-shadow: 0 3px 24px rgba(0,0,0,.15);
          border-radius: 8px;
          height: auto;
          .team-img {
            transition: all .6s;
            width: 220px;
            height: 220px;
          }
          .desc {
            transition: all .6s;
            overflow: initial;
            text-overflow: initial;
            line-height: 1.6;
            /*! autoprefixer: off */
            display: initial;
            -webkit-line-clamp: initial;
            -webkit-box-orient: initial;
          }
        }
        .team-img {
          width: 332px;
          height: 332px;
        }
        .name {
          margin-top: 24px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 20px;
        }
        .tag-text {
          margin-top: 14px;
          min-width: 207px;
          padding: 6px 42px;
          border: 2px solid #317BFF;
          border-radius: 12px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #317BFF;
          line-height: 20px;
        }
        .desc {
          overflow: hidden;
          text-overflow: ellipsis;
          /*! autoprefixer: off */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          margin-top: 32px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          text-indent:2em;
          text-align: left;
          width: 100%;
          white-space: normal;
          // flex: 1;
          div {
            width: 100%;
            white-space: normal;
          }
        }
      }
    }
  }
  // *******************资深讲师******************************** */

  // *******************成长激活******************************** */
  .grow-block {
    background-size: 100%;
    .title-img {
      margin-top: 50px;
      width: 530px;
      height: 68px;
    }
    .content-block {
      margin-top: 46px;
      width: 100%;
      .content-title-block {
        width: 100%;
        .tag-text {
          padding: 13px 33px;
          border: 1px solid #444444;
          border-radius: 23px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #444444;
          line-height: 20px;
          &:hover {
          border: 1px solid #317BFF;
            color: #FFFFFF;
            background: #317BFF;
          }
        }
      }

      .content-sub-block {
        margin-top: 57px;
        width: 100%;
        text-align: left;
        .content-sub-item {
          width: 539px;
          .grow-sub-img {
            width: 56px;
            height: 56px;
          }
          .right-grow-sub-item {
            margin-left: 19px;
            flex: 1;
            .grow-sub-title {
              font-size: 22px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
              line-height: 20px;
            }
            .grow-sub-block {
              width: 100%;
              .grow-sub-item {
              width: 100%;
                margin-top: 24px;
                .title {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #000000;
                  line-height: 20px;
                }
                .desc-text {
                  margin-top: 10px;
                  width: 100%;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #000000;
                  line-height: 26px;
                }
                .desc-type-2 {
                  margin-top: 10px;
                  margin-right: 8px;
                  padding: 6px 12px;
                  border: 1px solid #666666;
                  border-radius: 2px;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #444444;
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
      .more-btn {
        margin: 120px 0 57px 0;
        padding: 17px 45px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        background: #317BFF;
        border-radius: 26px;
      }
    }

  }
  // *******************成长激活******************************** */

  // *******************特色服务******************************** */
  .service-block {
    width: 100vw;
    background: #F3F4F6;
    .item-block {
      .title-img {
        margin-top: 50px;
        width: 273px;
        height: 68px;
      }
      .content-block {
        width: 100%;
        .content-item {
          margin-top: 50px;
          width: 309px;
          .icon {
            width: 122px;
            height: 122px;
          }
          .content-text {
            margin-top: 26px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #000000;
            line-height: 26px;
          }
        }
      }
    }
      .more-btn {
        margin: 120px 0 57px 0;
        padding: 17px 45px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        background: #317BFF;
        border-radius: 26px;
      }
  }
  // *******************特色服务******************************** */



  // *******************大纲列表******************************** */
  .outline-block {
    padding: 50px 0;
    .outline-half {
        width: 578px;
    .ouline-item-block {
      width: 100%;
        box-sizing: border-box;
        background: linear-gradient(45deg, #E2E2E2 0%, #F9F9F9 100%);
        &.show {
          margin-top: 52px;
          padding: 24px;
        }
          .title {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #317BFF;
          line-height: 20px;
          width: 100%;
        }
        .desc {
          margin-top: 13px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #000000;
          line-height: 20px;
          width: 100%;
          text-align: left;
        }

        .chapter-block {
          margin-top: 24px;
          width: 100%;
          .chapter-title {
            width: 100%;
            box-sizing: border-box;
            padding:  10px 26px;
            background: #FFFFFF;
            border-radius: 4px;
            text-align: left;
          }
          .chapter-item-container {
            display: none;
          }
          .content-item {
            margin-top: 30px;
            width: 100%;
            padding: 0 26px;
            box-sizing: border-box;
            text-align: left;
            &.first {
              margin-top: 26px;
            }
            &:hover {
              color: #317BFF;
            }
          }
        }
      }
      
    }
  }
  .hidden {
    visibility: hidden;
  }
  // *******************大纲列表******************************** */

  

</style>