<template>
    <!-- 产品体系 -->
      <div id="product-block" ref="product-block" class="product-block main-width flex-row flex-center">
        <div class="main-width flex-row flex-center">
          <!-- 标题 -->
          <div class="title-view flex-column flex-middle">
            <div class="line"></div>
            <div class="text">耳穴堂产品体系</div>
            <div class="line"></div>
          </div>

          <!-- 副标题 -->
          <div class="title2">耳穴堂集技术提升、行业交流、资源整合共享与一体，旨在打造良好氛围的全球耳穴交流圈子，为耳穴人提供一站式终身服务</div>

          <!-- 产品列表 -->
          <div class="product-list flex-column flex-middle flex-around">
            <div v-for="productItem,index in productArr" :key="index" class="product-item flex-row flex-center" @click="showAlertAction(productItem.alertData)" >
              <img :src="productItem.icon" class="icon" />
              <div class="title1">{{productItem.title}}</div>
              <div class="title2">{{productItem.desc}}</div>
            </div>
          </div>
        </div>

        <!-- 弹框 -->
    <qr-code-alert ref="codeAlert" :alertData="alertData"></qr-code-alert>
    <!-- 弹框End -->
      </div>
    <!-- 产品体系End -->
</template>

<script>

import QrCodeAlert from './QrCodeAlert'

export default {
  name: 'ProductionList',
  components: {
    QrCodeAlert
  },
  data() {
    return {
    // *******************产品体系******************************** *//
     productArr: [
       {
         title: '耳穴库小程序',
         icon: require('@/assets/icons/index_xyfk_one@2x.png'),
         desc: '耳穴库小程序提供国内海量的耳穴资料查询，穴位配方、答疑解惑，耳穴论文、书籍、耳穴人经验分享文案、身边耳穴师等',
         alertData: {
            title1: '扫码咨询',
            title2: '耳穴库小程序提供国内海量的耳穴资料查询，穴位配方、答疑解惑，耳穴论文、书籍、耳穴人经验分享文案、身边耳穴师等',
            qrcodeUrl: require('@/assets/miniprogram.png'),
            title3: '请使用微信扫描二维码'
         }
       },
       {
         title: '耳穴堂商城',
         icon: require('@/assets/icons/index_xyfk_two@2x.png'),
         desc: '耳穴优选商城，集中了专业耳穴工具、耗材、图书、耳穴仪器等，是耳穴人一站式购物的选择。',
         alertData: {
            title1: '扫码咨询',
            title2: '耳穴优选商城，集中了专业耳穴工具、耗材、图书、耳穴仪器等，是耳穴人一站式购物的选择。',
            qrcodeUrl: require('@/assets/official.jpg'),
            title3: '请使用微信扫描二维码'
         }
       },
       {
         title: '在线耳穴课程',
         icon: require('@/assets/icons/index_xyfk_three@2x.png'),
         desc: '从入门到专业的精品课程，众多大师巨作，是耳穴学习者的常用视频。',
         alertData: {
            title1: '扫码咨询',
            title2: '从入门到专业的精品课程，众多大师巨作，是耳穴学习者的常用视频。',
            qrcodeUrl: require('@/assets/official.jpg'),
            title3: '请使用微信扫描二维码'
         }
       },
     ],
     // *******************产品体系******************************** *//

    // *******************弹框******************************** *//
      alertData: {},
      // 联系客服弹框数据
      contactsAlertData: {
        title1: '扫码咨询',
        title2: '请用微信扫码添加客服：耳穴堂明明',
        qrcodeUrl: require('@/assets/footer/qr_contact.jpeg'),
        title3: '请使用微信扫描二维码'
      },
      
      // *******************弹框******************************** *//



    }
  },
  methods: {
    // *******************弹框******************************** *//
    showAlertAction(alertData) {
      if (alertData) {
        this.alertData = alertData
        this.$refs.codeAlert.showSelf()
      }
    }
    // *******************弹框******************************** *//

  }
}
</script>

<style scoped lang="less">

  // *******************产品体系******************************** */
  .product-block {
    width: 100vw;
    padding: 70px 0;
    background: #FBFBFB;
    .title-view {
      .line {
        background: #E9E9E9;
      }
      .text {
        color: #212121;
      }
    }

    .title2 {
      margin-top: 22px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
      line-height: 20px;
    }

    .product-list {
      width: 100%;
      margin-top: 46px;
      .product-item {
        width: 400px;
        height: 330px;
        box-sizing: border-box;
        padding: 20px 50px 0 ;
        &:hover {
            background: #FFFFFF;
            box-shadow: 0px 4px 13px 0px #E4E4E4;
            border-radius: 10px;
            .title1 {
              color: #317BFF;
            }
          }
        .icon {
          width: 150px;
          height: 150px;
        }
        .title1 {
          margin-top: 10px;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #212121;
          line-height: 36px;
        }
        .title2 {
          margin-top: 16px;
          width: 100%;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ADADAD;
          line-height: 24px;
        }
      }
    }

  }
  // *******************产品体系******************************** */

</style>