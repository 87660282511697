<template>
  <div class="com-container flex-row flex-center">
    <!-- 轮播图 -->
    <home-swiper :pageMenus="pageMenus"></home-swiper>

    <!-- 公益课堂 -->
      <div id="commonweal-class-block" ref="commonweal-class-block" class="commonweal-class-block main-width flex-row flex-center">
        <!-- 标题 -->
          <div class="title-view flex-column flex-middle">
            <div class="line"></div>
            <div class="text">公益课堂</div>
            <div class="line"></div>
          </div>
          <!-- 特色类别 -->
          <div class="class-features main-width flex-column flex-between">
            <div v-for="item,index in classFeaturesArr" :key="index" class="feature-item flex-row flex-center" @click="featureItemTap(item)">
              <img :src="item.img" class="feature-div" />
              <div class="title">{{item.title}}</div>
            </div>
          </div>
      </div>
    <!-- 公益课堂End -->

    <!-- 课堂按钮 -->
      <div class="menu-block flex-row flex-center" :style="isTop ? 'top:0px':'top:64px'">
        <div class="main-width flex-column flex-middle">
          <div v-for="menuItem,index in menuArr" :key="index" class="menu-item" :class="{'selected': index == selectedMenuIdx}" @click="menuTap(index)">{{menuItem.title}}</div>
          <div class="consulting right-view text-center" @click="showAlertAction(contactsAlertData)">立即咨询</div>
        </div>
      </div>
    <!-- 课堂按钮End -->

    <!-- 大纲列表 -->
      <div class="outline-block main-width flex-column flex-between">
        <!-- 左边 -->
        <div class="outline-half flex-row flex-center">
          <div v-for="item,index in outlineDataArr" :key="index" class="ouline-item-block" :class="{'show' : index%2 == 0}">
            <div v-if="index%2 == 0" class="outline-item flex-row flex-center">
                <div class="title"> {{item.title}} </div>
                <div class="desc">{{item.desc}}</div>
                <div v-for="chapterItem,idxChapter in item.chapterArr" :key="idxChapter" class="chapter-block">
                  <div class="chapter-title text-middle" @click="chapterTap(index,idxChapter)">{{idxChapter+1}}：{{chapterItem.chaperTitle}} <span class="arrow down right-view"></span></div>
                  <div :ref="'chapter' + index +idxChapter" class="chapter-item-container">
                    <div v-for="contentItem,idxContent in chapterItem.contentList" :key="idxContent" class="content-item" :class="{'first' : idxContent==0}">{{contentItem}}</div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <!-- 右边 -->
        <div class="outline-half flex-row flex-center">
          <div v-for="item,index in outlineDataArr" :key="index" class="ouline-item-block" :class="{'show' : index%2 != 0}">
            <div v-if="index%2 != 0" class="outline-item flex-row flex-center">
                <div class="title"> {{item.title}} </div>
                <div class="desc">{{item.desc}}</div>
                <div v-for="chapterItem,idxChapter in item.chapterArr" :key="idxChapter" class="chapter-block">
                  <div class="chapter-title text-middle" @click="chapterTap(index,idxChapter)">{{idxChapter+1}}：{{chapterItem.chaperTitle}} <span class="arrow down right-view"></span></div>
                  <div :ref="'chapter' + index +idxChapter" class="chapter-item-container">
                    <div v-for="contentItem,idxContent in chapterItem.contentList" :key="idxContent" class="content-item" :class="{'first' : idxContent==0}">{{contentItem}}</div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    <!-- 大纲列表End -->
        <!-- 弹框 -->
    <qr-code-alert ref="codeAlert" :alertData="alertData"></qr-code-alert>
    <!-- 弹框End -->
  </div>
</template>

<script>
import HomeSwiper from '../components/HomeSwiper.vue'
import QrCodeAlert from '../components/QrCodeAlert'

export default {
  name: 'EnterEXK',
  components: {
    HomeSwiper,
    QrCodeAlert
  },
  metaInfo: {
    mata: [
      {
        name: 'keywords',
        content: '耳穴培训,耳穴挂图,耳穴位,耳朵穴位图,耳朵穴位,耳针图谱,耳穴,耳针,耳针疗法,耳穴疗法,耳穴医学,耳医学,贴耳穴,学耳穴,耳穴联盟,耳穴加盟,耳疗,耳穴堂,耳穴库,耳穴淘宝,耳穴视频,耳穴培训,耳穴图,耳穴网站'
      },
      {
        name: 'description',
        content: '耳穴库网是耳穴公益性学习网站提供一站式耳穴培训,是青岛耳穴堂公司推出的集耳穴定位、疾病配方、耳穴培训,耳穴工具、视频教程、经验分享、传承基地、论坛于一体的综合性网站，耳穴库网致力成为国内耳穴人交流耳穴的互联网平台。'
      }
    ]
  },
  data() {
    return {
      // *******************banner******************************** *//
      pageMenus: [
        {
          name: '名称4',
          icon: 'https://static.cdn.erxueku.com/exk/9.jpg',
          bgColor: '#e8f7ff',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNWVlMDVkMjYzYmY1ZV9pa3VxWUI3MiIsImNoYW5uZWxfaWQiOiIiLCJyZXNvdXJjZV9pZCI6IiJ9'
        },
         {
          name: '名称4',
           icon: 'https://static.cdn.erxueku.com/exk/g2.png',
          bgColor: '#eeebe2',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNjAzMWI1YTllNGIwZjE3NmFlZDMwMjg3IiwiY2hhbm5lbF9pZCI6IiIsInJlc291cmNlX2lkIjoiIn0'
        },
        {
          name: '3d',
          icon: 'https://static.cdn.erxueku.com/exk/app3.png',
          bgColor: '#2f4469',
          url: 'https://mp.weixin.qq.com/s/3OMwW-6jEqPNbnwc_DrUDg'
        },
        {
          name: '商城',
          icon: 'https://static.cdn.erxueku.com/exk/sc4.png',
          bgColor: '#ecaf5c',
          url: 'https://shop45067782.m.youzan.com/v2/feature/iUUH2VMZTh?kdt_id=44875614&scan=3&from=singlemessage&sl=undefined&st=1&sf=wx_sm&is_share=1&share_cmpt=native_wechat&from_uuid=8f130a9d-4d9f-3ca7-07a8-a8981167a3ea'
        },
        {
          name: '小程序',
          icon: 'https://static.cdn.erxueku.com/exk/xcx5.png',
          bgColor: '#fdf5ec',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNjAzMWI1YTllNGIwZjE3NmFlZDMwMjg3IiwiY2hhbm5lbF9pZCI6IiIsInJlc291cmNlX2lkIjoiIn0',
          alertType: 1
        }
      ],
      // *******************banner******************************** *//

      // *******************公益课堂顶部数据******************************** *//
      classFeaturesArr: [
        {
          title: '中科院：耳穴-奇妙的方寸之郭',
          img: require('@/assets/enterEXK/01.png'),
          url: 'http://apphmff2qch5525.h5.xiaoeknow.com/v2/course/alive/l_60291021e4b0f176aed1d4c3?app_id=apphMff2Qch5525&alive_mode=0&pro_id=&type=2&is_redirect=1'
        },
        {
          title: '耳穴培元固本按摩操',
          img: require('@/assets/enterEXK/02.png'),
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/v1/course/alive/l_5e329b6f82362_91zOx4Et?app_id=apphMff2Qch5525&alive_mode=0&pro_id=&type=2&is_redirect=1&liveType=previous'
        },
        {
          title: '耳穴防控近视家庭版',
          img: require('@/assets/enterEXK/03.png'),
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/v1/course/alive/l_5e4e2e466e04e_uOvGeL0u?app_id=apphMff2Qch5525&alive_mode=0&pro_id=&type=2&is_redirect=1&liveType=previous'
        },
        {
          title: '不懂这些千万不要学耳穴',
          img: require('@/assets/enterEXK/04.png'),
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/v1/course/alive/l_5e7d93d8be6c3_EgSM4Z4c?app_id=apphMff2Qch5525&alive_mode=0&pro_id=&type=2&is_redirect=1&liveType=previous'
        },

      ],
      // *******************公益课堂顶部数据******************************** *//

      // *******************中部按钮******************************** *//
      isTop: false,//是否滑动菜单栏到顶部了
      scrolltop: 0,//滑动距离顶部距离
      selectedMenuIdx:0, //选中索引
      menuArr: [
        {
          title: '了解耳穴',
          eleId: ''
        }
      ],

      // *******************中部按钮******************************** *//

      // *******************大纲数据******************************** *//
      outlineSpreadInfo: {}, //大纲菜单展开信息 以第几章第几节为key
      outlineDataArr: [
        {
          title: '什么是耳穴？',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '什么是耳穴',
              contentList: [
                '耳穴是耳郭皮肤表面与人体脏腑经络、组织器官、四肢百骸相互沟通的部位，耳郭上能反应机体生理功能和病理变化的部位统称耳穴。',
              ]
            },
            {
              chaperTitle: '什么是耳穴疗法？',
              contentList: [
                '耳穴疗法是指在耳郭相应穴位上用针刺、贴压、注射、割治、贴膏等方法进行刺激以达到治疗、预防疾病作用的一种外治方法。',
              ]
            },
            {
              chaperTitle: '耳穴起源于哪里？',
              contentList: [
                '我国耳穴起源于古代，流传于民间，散载于历代医学著作之中。1973年，在长沙马王堆三号汉墓出土的帛书中，就记载耳与上肢、眼、颊、咽喉相联系的“耳脉”。春秋战国末期的《黄帝内经》中，载述与耳穴诊治的经文有56条。',
              ]
            },
            {
              chaperTitle: '耳穴为什么能治病？',
              contentList: [
                '①生物全息论：耳廓这个独立部分是人整体的缩影，耳廓包含了人体各部分的信息，耳廓不仅与脏腑的生理活动有关，而且在病理改变上也是不可分割的。刺激耳廓不同部位可以调节全身各组织、器官，以治疗各种疾病。',
                '②神经学说：人体12对脑神经，其中6对与耳朵有直接关联。从神经解剖学上可以看到耳廓部分神经稠密，有耳大神经、枕小神经、面神经、舌咽神经等，这些神经分别和大脑、大脑皮层、脊髓等中枢神经、高级神经中枢直接联系起来了。',
                '③经络学说：从经脉循行的规律来看，六条阳经或直入耳中，或布于耳周；六条阴经则通过络脉与耳相关联，或通过经别与阳经相合后上达于耳，使十二经脉都直接或间接地与耳发生联系。',
                '④德尔塔反射学说：“德尔他反射”是用胶布将电子测温计探头固定在耳廓的手、足、膝、腹等区点上，待测温计指针稳定后，双手或足膝等部位用冷、热或扎针进行刺激；10-15秒内，耳廓上与受刺激部位的相应的区域皮温上升1到5.5摄氏度，维持时间不等，最长可达2小时以上，并有个体差异，耳廓上不相应的区域未见温度升高。同样，刺激耳廓某区点也可在相应的躯体上出现皮温升高。',
              ]
            },
            {
              chaperTitle: '耳穴疗法适应症有哪些？',
              contentList: [
                '痛症：头痛、牙痛、胃痛、痛经、落枕、急性腰扭伤、踝扭伤、肿瘤止痛',
                '炎症：急性结膜炎、中耳炎、扁桃体炎腮腺炎、胃炎、阑尾炎、附件炎',
                '功能性疾病：内耳眩晕症、心律不齐、高血压',
                '妇科疾病：盆腔炎、月经不调、功能性子宫出血',
                '过敏性疾病：过敏性鼻炎、过敏性哮喘、过敏性结肠炎、荨麻疹',
                '神经性疾病：神经衰弱、失眠、抑郁、焦虑',
                '儿科：扁桃体肥大、腺样体肥大、小儿厌食、小儿遗尿、小儿增高',
                '其它催产催乳晕车戒烟祛眼袋、面部提升',
              ]
            },
            {
              chaperTitle: '耳穴属于中医还是西医？',
              contentList: [
                '经近几十年来的发掘、整理、研究，逐渐发展成为中西医结合、融合古今、独具理论体系，中西医结合的一门边缘医学。',
              ]
            },
            {
              chaperTitle: '耳穴有哪些功用？',
              contentList: [
                '诊断、治疗、预防、保健、美容、康复、养生和抗衰老等八大功用于一体',
              ]
            },
          ]
        },
        {
          title: '不懂这些千万不要学耳穴',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '耳穴疗法适用于每个人吗？',
              contentList: [
                '耳穴疗法是通过针刺或者用王不留行籽或磁珠等，贴在穴位上进行刺激，治疗防治一些疾病的疗法，《黄帝内经》非常有名的一句话叫“耳者宗脉之所聚也”，耳廓是百脉之气汇聚集合的地方，我们在耳部的穴位上面进行刺激，可以疏通气血，可以调节脏腑，来达到防治疾病的这个效果。书中说，人皆因病而外求于医，却不知自身而乃良医，自身是真医，人身中有天地，有天地之大药，耳穴正是我们人身体内所自带的大药，每一个人都适合耳穴疗法。',
              ]
            },
            {
              chaperTitle: '学习耳穴需要什么条件、基础吗？',
              contentList: [
                '根据诉求不同分类我们将人分为以下三类：',
                '一：耳穴爱好者' ,
                '1、在职人员，有工作的人员，对中医有浓厚的兴趣，尤其是中医的外治法，这类人群只要掌握好基础的耳穴国标，就可以治疗一些简单的小病。',
                '2、全职妈妈，是有明确的学习目的就是为了针对自己孩子的健康，首先要求学习好耳穴国标，然后再学习其他专项病。',
                '3、自由职业者，想要介入到健康养生行业的人，是需要下苦功夫的，一定要有全面医学知识，包括中医的经络脏腑，西医的解剖，还有耳穴经验穴也要学习，不然很难保证疗效。',
                '二、养生馆',
                '1、学过耳穴的，要么是自学要么是在其他培训班学习的，但在实际应用中却做不出效果，或者是做不出特色，遇到了瓶颈，这类人群是需要在以后的学习中突破这个瓶颈。',
                '2、没有学过耳穴的，想要拓展业务增加收入，要想达到这个效果一定要深入学习医学知识，要不然你很难走远，也很难做出特色。',
                '三、健康讲座平台的人群目的是传播弘扬耳穴，这个只需要有耳穴基础知识就可以了。',
                '四、医护人员',
                '1、医生，一类是医院就职的医生，包含中医医师和西医医师，甚至专科医师，他们缺点是只出方子，自己不贴，让护士贴，对具体定位不是很明确，需要加强耳穴国标的学习；一类是基层医生，自己开门诊的，需要将耳穴疗法充盈到他的诊疗方法中，加强疗效，就需要更加踏实的研究耳穴国标，专项病的诊疗，以及经验穴的应用。',
                '2、护士，护士自己不开方子，不会辨证，不会组方，没有专业培训，贴的很随意，需要在学习时掌握贴压技巧和精准的穴位。',
              ]
            },
            {
              chaperTitle: '多长时间能学会？',
              contentList: [
                '人和人是不一样的，有人五天学会，有人半年学会。人在学习时用的是内感官，人群分为三类，第一类是以内视觉为主导的人群，对文字以及图片如思维导图、图表等感兴趣，记忆力深刻，适合学习线上视频课，掌握得非常快。第二类是以内听觉为主导的，对图片、思维导图不感兴趣，是靠听觉记忆的，这类人适合听音频课程；第三类人群是以内感觉为主导的学习方式，相对其他人群反应稍微慢点，接受能力差，就比较适合听线下课。' ,
                '建议可以先听线上课，内视觉的人群学习是没有问题的；内听觉的人群要多听多记忆，根据耳穴图、耳膜等加深理解；对于内感觉的人群现在可以在线上课上慢慢学，有了知识储备等线下课开了就可以更快的学好。所以根据各人的情况不一样，基础不一样，学习的时间也是不一定的。',
              ]
            },
            {
              chaperTitle: '耳穴疗法能自己开店吗？',
              contentList: [
                '如果单独的开一个耳穴养生馆，可以直接去工商管理注册，不需要特殊的手续，因为只要不针刺，不放血，不破皮，就属于按摩，不需要其他证件。谨慎提醒，“开店需谨慎”，耳穴养生馆一定要有自己主打的特色，否则很难走的很远。',
              ]
            },
            {
              chaperTitle: '耳穴疗法需要考证吗？',
              contentList: [
                '目前在国家层面并没有针对耳穴技术的专业认证，也没有相应的资格证书。但我们耳穴堂有系统的培训体系，通过系统的学习可以到得到我们耳穴堂的资格认证证书，以证明您的耳穴诊疗能力。',
              ]
            },
            {
              chaperTitle: '耳穴单独能治疗哪些症状？',
              contentList: [
                '专业的耳穴医师99%的都是单独使用耳穴治疗。除了部分耳穴美容，为了提高治疗速度，会辅助使用艾灸，但其他疾病的都是可以通过单独使用耳穴治疗的。',
                '耳穴治疗范围广，内科，妇科，儿科，五官，骨科等等都可以涉及到，可以治疗200多种疾病。但是没有一种疗法是万能的，都是有短板的，每一种疾病都有治疗窗，错过可治疗窗后，治疗效果就会差很多。',
              ]
            },
            {
              chaperTitle: '初学者哪几种疾病更容易掌握、效果好？',
              contentList: [
                '建议以简单的症候，以及常见病，多发病为基础，在没有深入学习和实践应用，不要感觉任何复杂的症候都用耳穴治疗，避免打击到自己和患者的积极性。',
                '以下病症耳穴诊疗可以快速掌握且效果好，比如：',
                '1、痛症，急性扭伤，头痛，痛经；',
                '2炎症，急性结膜炎，急性扁桃体炎；',
                '3神经系统的疾病，神经衰弱，失眠，多梦；',
                '4过敏性疾病，荨麻疹，蚊虫叮咬等。以上这些病症都是均有见效快，疗效好的常见病。',
              ]
            },
            {
              chaperTitle: '耳穴疗法的禁忌症有哪些？',
              contentList: [
                '首先要保证耳郭皮肤是完整的，如有炎症，冻疮，溃疡等的就不要用耳穴疗法了。有严重的心脏疾病或者习惯性流产的孕妇，尽量不要治疗，一般不懂医的人是不建议治疗孕妇的，防止出现意外。',
              ]
            },
            {
              chaperTitle: '现在耳穴派系很多，不知道该怎么选择？',
              contentList: [
                '中医存在很多派系，每一个派系的老专家教授都会对他所研究的内容有独到的见解，每位的出发点不一样，观点不一样，但没有谁对谁错，都是为了治疗疾病。能有机会去聆听各位老专家教授的课程，应该是幸运幸福的，是可以去多多学习的，建议在听课之间一定打好耳穴国标基础。耳穴国家标准就好比普通话，只有学好了基础，再去学习老专家的课程，才可以学习到并提炼出其中的精髓。',
              ]
            },
            {
              chaperTitle: '耳穴区域特别小，如何快速学会并应用？',
              contentList: [
                '一般人的耳朵大约是4厘米宽，7厘米长，学习耳穴首先要学习耳穴国标，从方向，解剖部位，辅助线等具体知识对耳朵进行分区，要首先在平面图上了解耳穴分区，然后升级到耳膜上，掌握理解记忆了，再到真人的耳朵去应用。按照从平面图到耳膜到真人耳朵的路径，一步一步的走。',
              ]
            },
            {
              chaperTitle: '一定要学国标耳穴吗？国标划线有意义吗？',
              contentList: [
                '学习国标耳穴是有非常重要意义的，国标耳穴就是耳穴的普通话，学会了普通话才能学习其他专家教授的经验，才能准确的在真人耳朵上定位，才能有较为确切的疗效。耳朵上的标志线是老专家在制定国标耳穴的时候划定的，由于每个人的耳朵都是不一样，也不是按照国标生长，情况复杂多样。只有按照国标耳穴的各种辅助线和标志线，才能确定这些线是在耳朵上是如何判定的，才能在生长不标准的耳朵上找到准确的分区和点。',
              ]
            },
            {
              chaperTitle: '不学国标划线，直接找痛点贴不就行了吗？',
              contentList: [
                '1、痛点也是有假阳性和真阳性，耳穴组方最重要的就是相应部位阳性点的确定，但每个人的痛感是不一样的，所以找到的痛点不一定就是真的阳性点；',
                '2、如果不知道耳穴分区，单单靠找痛点去确定，需要在耳朵上花费大量时间探查才能确定位置，从时间上来算就是非常不划算的。',
              ]
            },
            {
              chaperTitle: '直接学习耳穴经验穴可以吗？',
              contentList: [
                '没有耳穴国标基础，就不会耳穴的普通话，老师讲的课程是听不懂的，也很难学会的。经验穴更是需要精准的定位，只有在耳穴国标分区的基础上，才能在各个分区内，通过辅助线和标志线，对经验穴的点进行精确定位。经验穴要想起到作用，必须要求精准，方向和角度都是有要求的，不能马虎，否则是一点作用也没有的。',
                '耳穴国标就像一棵绿植，上面的花是经验穴，经验穴是建立在国标基础上，如果只有这一朵花，没有主干和绿叶，请问它能活多久？经验穴是老一辈专家在临床应用中发现能起到画龙点睛的作用的穴位，是在国标的基础之上的有效穴位。',
              ]
            },
            {
              chaperTitle: '耳穴诊和治都要学吗？',
              contentList: [
                '诊是诊断，治是治疗，没有诊断只有治疗，就不能准确的判断疾病的具体情况，无法有针对性的进行治疗；没有治疗只有诊断，就是纸上谈兵，耳穴是门实用性技能，学习耳穴就是为了解除病痛，只有通过治疗才能确定诊断是否正确，所以耳穴诊断和治疗的学习是缺一不可的。',
              ]
            },
            {
              chaperTitle: '耳穴线下实操课有必要吗？',
              contentList: [
                '线下课时老师会手把手的检验学员对耳穴国标的掌握情况，及时纠正定位错误。对于一些治疗的手法，如按压角度，贴压方向等，也只有在线下课才能看的明白，讲的清楚。',
              ]
            },
          ]
        },
        {
          title: '入门常见问题',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '学好了国标还有必要学经验穴吗？',
              contentList: [
                '经验穴犹如绿植的花朵，是在治疗过程中起画龙点睛效果的穴位，要想治疗更有效果，提高治疗效率，就必须学习经验穴。',
              ]
            },
            {
              chaperTitle: '网上有很多耳穴处方，为什么我按着贴了无效？',
              contentList: [
                '没有一个处方是万能的，任何疾病的治疗都需要处方对症，网上的处方不一定是针对于你的病情的，药不对症怎么会有效果呢？不同的病情，不同的患者，不同的疾病发展期，都是需要根据这些不同的情况给出对证的治疗方案和组方的。',
              ]
            },
            {
              chaperTitle: '按照耳穴处方，为什么会效果递减：不显著、无效？',
              contentList: [
                '治病要标本兼治，在不清楚导致疾病根本原因的情况下按照耳穴处方治疗，只能起到“治标”的效果，只有针对疾病进行合理辨证，找到致病根源，进行相应配方组穴，才能彻底从“本”上治好疾病。',
              ]
            },
            {
              chaperTitle: '相同的病不同的专家给出的组方不一样，该怎么选用？',
              contentList: [
                '不同专家针对某种疾病治疗时的切入点、认知点，治疗思路不一样，在选用是要根据具体的病人和病情，分析不同专家组方的治疗侧重点和治疗思路，有针对性的选择使用。',
              ]
            },
            {
              chaperTitle: '耳穴的配穴需要遵循什么原则？',
              contentList: [
                '累加式配穴原则；',
                '1、根据相应部位取穴；',
                '2、根据中医脏腑经络理论取穴；',
                '3、根据西医神经内分泌学说取穴；',
                '4、根据经验穴取穴',
              ]
            },
            {
              chaperTitle: '贴穴位的顺序、数量、方向有没有讲究限制？',
              contentList: [
                '贴穴位的顺序就是配穴的顺序。',
                '耳贴数量要求是少而精，根据病症和严重情况来确定耳穴贴的数量，同一疾病的急性发作和慢性病取穴数量是不同的，越是复杂的疾病取穴的数量也是相应较多的。',
                '贴压的方向根据穴位不同，方向和角度也是不同的。',
              ]
            },
            {
              chaperTitle: '两只耳朵取穴与分布有讲究吗？贴单耳双耳？',
              contentList: [
                '是有讲究的，比如人体下肢对应的反应点95%是在人体对侧的耳朵；上肢和头面部对应的反应点在人体同侧的耳朵；人体左侧的脏腑在人体左侧耳朵找反应点，人体右侧的脏腑在人体右侧耳朵找反应点。',
                '不应纠结是贴单耳还是双耳，应该是纠结这个穴位贴在左耳有效还是右耳更有效，贴在效果好的一侧。',
              ]
            },
            {
              chaperTitle: '一天按几次、几天换一次好？',
              contentList: [
                '王不留行籽的耳贴一天需要按压5-6次，每次2-3分钟，以把耳廓按压发红为度。',
                '贴5天休息2天再贴。',
                '磁珠耳穴贴时不需要患者自己按压的。',
              ]
            },
            {
              chaperTitle: '一个疗程是多久？',
              contentList: [
                '根据疾病病情不同，疗程不一定。急性病可能1-2次就可以，慢性病有可能3-6个月一个疗程。',
              ]
            },
            {
              chaperTitle: '耳穴压豆按压手法特别吗？按压时一定要达到痛才有效吗？',
              contentList: [
                '王正老师的《耳穴医学信息报》第5页有详细的按压手法。',
                '只有疾病的相应部位的阳性反应点才是按压有较明显疼痛感的穴位，组方当中其他穴位不需要按压到疼痛才有效。想要治疗有确切疗效，精准的定位才是最重要的。',
              ]
            },
            {
              chaperTitle: '各种材料的耳穴贴该如何选择？',
              contentList: [
                '目前较为普遍使用的是王不留行籽的耳穴贴和磁珠的耳穴贴，其中王不留行籽耳穴贴需要经常按压且有痛感，不容易坚持。对于小孩子使用按压力度不容易掌握，按压后往往出现皮损，等皮损修复好后才能继续贴压，影响疗程。',
                '磁珠耳穴贴是不需要按压的，就没有以上的不良情况的出现。',
              ]
            },
            {
              chaperTitle: '磁珠到底要不要按压？',
              contentList: [
                '经过多年多人临床实践证明磁珠不按压是确切有效。',
              ]
            },
            {
              chaperTitle: '磁珠是否可以像王不留一样，有时也用双豆、三豆、四豆？',
              contentList: [
                '可以，耳穴堂就有双豆，三豆、四豆的磁珠耳穴贴。',
              ]
            },
            {
              chaperTitle: '磁珠不分补泻，那么用王不留子贴压的穴位，不管补泻都适合用磁珠吗？',
              contentList: [
                '需要补时，正常贴压磁珠即可，不用专门按压；需要泻时，重点穴位的磁珠在贴压时和贴压后，采用一定的角度、方向和一定的量去按压，患者回家后不需要自己按压磁珠。',
              ]
            },
            {
              chaperTitle: '如果找不准穴位，或者是贴在穴位附近，是不是也有效果？',
              contentList: [
                '耳穴穴区与相对应的脏腑的大小比例约是1：500，而脏腑具体病变部位在耳穴上的反应点就更小了，穴位的准确性直接关系到治疗效果。',
              ]
            },
            {
              chaperTitle: '如果从理论上分析某个区域是应该贴的，但是没有贴到阳性反应点上，贴到了区域上，能有一半以上的效果吗？',
              contentList: [
                '如果没有贴压到阳性反应点，没有直接刺激到阳性反应点，治疗效果的不能确定的。贴在阳性点附近可能会有一定效果，但远离阳性点的话是不会有治疗效果的。',
              ]
            },
            {
              chaperTitle: '贴耳穴该如何收费？',
              contentList: [
                '建议按病种收费，收费不要太低，根据当地消费水准和对耳穴技术要求不同合理收费即可。',
              ]
            },
          ]
        },
        {
          title: '耳穴应用问题',
          desc: '',
          chapterArr: [
            {
              chaperTitle: '顾客贴耳穴过程中出现痒、红肿、疼痛等反应怎么办？',
              contentList: [
                '痒的两种情况：',
                '1是贴压后出现整个耳朵发红发痒，可能是患者对贴压胶布或其他耳穴贴出现了过敏反应，要及时处理；',
                '2是只有某个相应部位的耳穴贴出现发痒的情况，其他的耳穴贴不痒，这是正常的反应，是治疗有效果的表现。',
                '红肿的情况一般会发生在对耳轮上面的穴位，因为对耳轮是凸起的软骨，患者在睡觉是可能由于压迫、刺激等出现了无菌性的红肿，只要将耳穴取下做消毒处理，一到两天就会恢复。',
                '部分耳穴贴豆后，由于受到外界其他物品的刺激可能会出现疼痛的反应，这是受刺激后的正常情况，只要提前和患者沟通好，告诉他某些穴位不要触碰，避免刺激，就不会出现这种剧烈疼痛的。',
              ]
            },
            {
              chaperTitle: '为什么刚开始学耳穴，贴的效果非常好，然后越来越差了呢？',
              contentList: [
                '刚才是学耳穴时，由于没有经验，只治疗一些基本的单纯的常见病和多发病，并且会仔细探查阳性点的位置，所以会有较好的疗效，但随着治疗的深入，就会开始尝试治疗一些复杂的病症，如果没有能力辨别病症的根本性原因，无法从疾病源头治疗，所以就会出现没有效果的情况。',
                '耳穴疲劳也是初学者容易忽视的，耳穴受到长时间的刺激，就会出现疗效减弱的现象，休息一段时间后，贴压效果就会自己恢复。',
              ]
            },
            {
              chaperTitle: '如何确定是否适合贴豆疗法？',
              contentList: [
                '只有部分患有禁忌症的人群是不适宜贴豆疗法，除此之外任何人都是适合采用耳穴贴豆疗法来治疗疾病的。只要保证耳穴定位准确，组方配穴合理，贴压手法正确，治疗就是会有确切疗效，一定要从自身出发，寻找自身的问题。',
                '(1）如我为一同学治慢性咽炎，快三周了，咽喉，口，气管，三焦，肾上腺等贴压仍然敏感很痛，但症状不见明显好转，是否继续进行？还是不适合贴豆？',
                '慢性咽炎一般多发于说话比较多的如教师或有长期吸烟史的烟民，患病时间长，原因复杂，具体情况不一样。根据中医脏腑经络理论来分析，人体十二正经除膀胱经外的十一条经络都直接或间接的经过咽喉处，不同具体咽喉部位的疼痛，循行的经脉也是不一样的，以上的配穴组方只关注了疼痛问题，没有找到治病的原因，没有对相应的脏腑进行调理，并不是一个完整的组方，所以效果不明显。并不是患者或病症不适合采用耳穴贴压疗法，而是对疾病分析，配穴组方出现了失误。',
                '(2）一同事颈后正中有一硬肿块，无症状，颈穴压痛强烈，贴豆近一月，穴感，肿块仍然如故，是否适合耳豆法？是否该放弃？',
                '此问题关于疾病症状的关键词是“硬肿块”，“无症状”，可以移动的硬肿块是可能淋巴结，恶性疾病是不易移动的，无症状说明按压是不疼的，如果仅仅是按压不疼痛的肿大淋巴结是不需要治疗的。耳穴就如同人体的病历本，以前的疾病都会在耳穴上留下相应的反应，耳穴按压疼痛不能直接说明该部位此时就确实患有某些疾病，是需要综合分析患者整体病情来确定疾病的。',
              ]
            },
            {
              chaperTitle: '有的初学者为了耳穴贴压穴位“命中率”，多数耳穴贴压用双豆贴，这样覆盖到所要贴的地方的概率增大。请问耳穴里有哪些地方的耳穴离得很近但是却要注意不要同时贴压到，不然会矛盾或互相抵消作用？这种在用多豆贴的时候就需要特殊注意了。',
              contentList: [
                '使用多豆贴并不会提高所谓的“命中率”，只有精确到真正的阳性反应点治疗才会有效，如果贴上多豆贴但并没有精准的贴压到阳性点，贴几个豆的贴都是无效的，命中率都是0 。',
                '在实际临床中，主要是根据疾病和病情来确定是否使用多豆贴。',
              ]
            },
            {
              chaperTitle: '贴耳穴几次之后，耳穴敏感度下降，调理效果不好，需要间隔几天再进行治疗？',
              contentList: [
                '由于耳穴受到长时间、同等力度、不间断的刺激，痛欲发生了改变，敏感性变差了，出现穴位疲劳，治疗效果会降低。一般贴压是5天为一次，休息2天后，再贴压。',
              ]
            },
            {
              chaperTitle: '耳穴的疲劳恢复期需要多长时间？',
              contentList: [
                '最少2天',
              ]
            },
            {
              chaperTitle: '针对近视方面，耳穴开始有效，为什么最后就收效甚微呢？',
              contentList: [
                '近视分为真性近视和假性近视，如果是真性近视，眼球结构已经发生改变，耳穴疗法也是无法将真性近视恢复到正常视力的，只能是缓解症状，确保近视度数不再增高。耳穴对于假性近视的治疗效果好，通过合理配穴和治疗可以将视力恢复正常。儿童7岁前是治疗近视的黄金期，治疗效果好，7岁后治疗效果就不理想了。同时治疗近视也是需要患者和家长共同配合的，不能单纯依靠耳穴治疗。',
              ]
            },
            {
              chaperTitle: '穴位组方中有的穴位探测阳性并不明显或无，是继续用，还是放弃该穴？（如湿疹在肺穴无阳性反应）。',
              contentList: [
                '阳性反应点分为以下几种形式，',
                '1是颜色的改变，',
                '2是血管的改变',
                '3是隆起或凹陷',
                '4脱屑等，湿疹肯定在肺区是有阳性反应点的，只是你没有找到，肺穴是治疗湿疹的必选穴位，是不能放弃的。',
              ]
            },
            {
              chaperTitle: '治戒烟和慢性咽炎都用肺，但实际上两个患者肺阳性探压不明显，但气管穴却非常疼，是否该舍肺而取气管？',
              contentList: [
                '耳穴堂经验穴高级班中会学习到戒烟和慢性咽炎的具体阳性反应点就是出现在气管区的上方和下方的位置，在高级班中我们已经综合好了这些具体的内容，您可以选择参加。',
              ]
            },
            {
              chaperTitle: '卵巢，睾丸两穴按全息投影应在三角窝，但为何在对耳屏内侧出现？',
              contentList: [
                '卵巢和睾丸在耳穴上是分别有两个相应的点，作为人躯体上的卵巢和睾丸其相应部位分别在三角窝和内生殖器。对耳屏内侧面的卵巢和睾丸是人体大脑皮质层调控卵巢激素和睾丸激素的功能点。',
              ]
            },
            {
              chaperTitle: '阑尾穴有几个，功能是否有差别，在实际用时是全选，还是选阳性强的？',
              contentList: [
                '治疗阑尾相关疾病首先要用的是耳穴国标中的“阑尾穴”，然后你再探查其他经验穴的阑尾穴，选择阳性反应最大的点或线使用。',
              ]
            },
            {
              chaperTitle: '四大止血穴和五大活血穴在应用时是选用几个还是全选？',
              contentList: [
                '四大止血穴是作为一个组合使用，想要达到止血的效果，这四个穴位是都要选用的。',
                '五大活血穴是具有针对性和靶向性的，需要根据不同部位不同病情选择不同穴位使用。',
              ]
            },
            {
              chaperTitle: '耳轮放血是相应的区域还是精准到点？',
              contentList: [
                '耳轮放血是选择相对应的区域进行放血，不需要精准到点，只有耳尖放血的时候，才需要进行精准到点放血。',
              ]
            },
            {
              chaperTitle: '如耳垂（轮6）是在下部边缘随便一点放血吗？',
              contentList: [
                '在穴区平行对应的耳轮边缘放血即可。',
              ]
            },
            {
              chaperTitle: '耳穴艾灸的细节操作及注意事项。',
              contentList: [
                '王正老师《图解耳穴诊治与美容》第79也有详细的说明，具体情况请参考图书。',
              ]
            },
            {
              chaperTitle: '对于不能用针的普通学习者，耳穴艾灸和贴豆对于炎症的疗效哪个更快更好用？',
              contentList: [
                '耳针对于急性病疗效好，贴豆对于慢性病的调理效果好；',
                '耳郭表面皮肤炎症，艾灸效果好于贴豆，如果耳廓有皮损是不能用耳豆刺激的。人体内脏的炎症可以先用艾灸，再贴豆，效果会比较好。',
              ]
            },
            {
              chaperTitle: '耳针用的针和体针用的针灸针有什么不同吗？',
              contentList: [
                '耳针规格一般是0.25mm*13mm或0.25mm*25mm的毫针，与体针只有规格大小的不同。',
                '另外还有其他针对耳穴使用的耳针，比如战场耳针，皮内针、揿针等。',
              ]
            },
            {
              chaperTitle: '还有像皮质下、心，轻刺激可能导致兴奋，但是如果是想起到镇静作用，需要用强刺激。',
              contentList: [
                '（1）这种时候用磁珠贴上不按摩也可以吗？',
                '（2）或者需要磁珠加上大力按压吗？',
                '（3）或者如果磁珠在同一个穴位贴多几粒、不按压，会形成强刺激的效果吗？就相当于泻吗？',
                '1、2、3问题一起回答。',
                '心区是不能强刺激的，尤其是神经质的人，否则可能会出现晕厥等意外情况。',
                '皮质下穴位想要达到镇静或兴奋的作用与刺激量无关，而是与刺激的角度方向有关。皮质下向着三焦方向贴压具有镇静作用，向着兴奋线贴压有兴奋的作用。',
                '（4）或者临近穴位贴、不按摩能达到强刺激的效果吗？比如磁珠在皮质下、枕、额三穴，各贴一豆，形成围贴，能达到强刺激、皮质下镇静而不是兴奋的作用吗？',
                '耳穴属于针灸学的一部分，保留了针灸学上的一些东西，而且是根据耳廓凹凸不平的形态，具有自己独特的一套理论，不能把体穴上的“围刺”方法用到耳穴上，这是不正确的。如果能用体穴解决的问题为什么要在耳穴用解决呢？\n' +
                '您所说的方法是达不到您想要的效果的。如果想要起到镇静或兴奋的作用，只要调整皮质下穴位贴压的角度和方向就能达到。\n',
              ]
            },
            {
              chaperTitle: '心包经，督脉，任脉三条体经上的病在耳穴上分别应选取哪三个对穴耳穴？',
              contentList: [
                '耳穴国标并未有涉及心包经、督脉、任脉的相关内容，我们在治疗相关疾病时可以选用与之相关的中医脏腑理论的穴位。督脉和任脉在耳穴上的分布可以参考陈克老师的《耳穴趣谈疗法》，尉迟静老师的相关书籍对人体十二经脉在耳穴的路线有详细的描述，大家可以参考学习。',
              ]
            },
            {
              chaperTitle: '耳穴压豆直接按穴位区贴上去可以吗？我看有的老师必须用探测器或者探测笔，特别是探测笔我觉得没大的意义。探测仪是不是不用自己学习穴位精确的位置靠仪器就可以了？',
              contentList: [
                '耳穴压豆是需要在穴区内找到精确的点，才会有疗效的。不同的专家都会有属于自己的探查方法，用探笔还是用探测仪是个人习惯，两者不存在谁对谁错，谁更比谁好的说法。',
                '仅用探测仪探测出来的阳性点也会存在假阳性点的情况，因为耳廓本身就有18个生理敏感点，即便在没有疾病的情况下，用探测仪也会提示阳性反应。',
              ]
            },
          ]
        },
      ],
      // *******************大纲数据******************************** *//

      // *******************弹框******************************** *//
      alertData: {},
      // 联系客服弹框数据
      contactsAlertData: {
        title1: '扫码咨询',
        title2: '请用微信扫码添加客服：耳穴堂明明',
        qrcodeUrl: require('@/assets/footer/qr_contact.jpeg'),
        title3: '请使用微信扫描二维码'
      },
      
      // *******************弹框******************************** *//

      
    }
  },

  mounted(){
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // *******************公益课堂******************************** *//
    featureItemTap(item) {
      window.open(item.url, '_blank')
    },
    // *******************公益课堂******************************** *//

    handleScroll() {
       let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
       if (scrolltop < this.scrolltop) {
         this.isTop = false
       }else {
        scrolltop > 428 ? (this.isTop = true) : (this.isTop = false);
       }
      this.scrolltop = scrolltop
    },
    // *******************按钮事件******************************** *//
    menuTap(index) {
      this.selectedMenuIdx = index
      // let toId = this.menuArr[this.selectedMenuIdx].eleId
      //  window.scrollTo({
      //     //滚动到元素位置
      //     //top: this.$refs[pos][0].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
      //     top: this.$refs[toId].getBoundingClientRect().top + window.scrollY - 68,//推荐使用。getBoundingClientRect 相对于当前视口的位置
      //     //top: 400//滚动到指定距离
      //     //top: 0,//滚动到顶部
      //     //top: document.documentElement.scrollHeight,//滚动到底部
      //     behavior: "smooth" // 平滑滚动
      //   });
    },
    // *******************按钮事件******************************** *//

    // *******************大纲相关******************************** *//
    chapterTap(index, idxChapter) {
      let chapterKey = 'chapter' + index +idxChapter
      console.log(this.$refs[chapterKey][0].style.display);
      if (this.$refs[chapterKey][0].style.display == '' || this.$refs[chapterKey][0].style.display == 'none') {
        this.$refs[chapterKey][0].style.display = 'block'
      }else {
        this.$refs[chapterKey][0].style.display = 'none'
      }
    },
    // *******************大纲相关******************************** *//

    // *******************弹框******************************** *//
    showAlertAction(alertData) {
      if (alertData) {
        this.alertData = alertData
        this.$refs.codeAlert.showSelf()
      }
    }
    // *******************弹框******************************** *//

    
  }
}
</script>

<style lang="less" scoped>
 .com-container  {
   width: 100vw;
 }
  // *******************标题******************************** */
    .title-view {
      .line {
        width: 21px;
        height: 3px;
        background: #E9E9E9;
      }
      .text {
        margin: 0 17px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #212121;
        line-height: 32px;
      }
    }
  // *******************标题******************************** */

  // *******************学员反馈******************************** */
  .commonweal-class-block {
    padding: 70px 0;
    background-color: white;
     .title-view {
      .line {
        background: #E9E9E9;
      }
      .text {
        color: #212121;
      }
    }
    // 特色
    .class-features {
      margin-top: 70px;
      .feature-item {
        width: 247px;
        .feature-div {
        width: 100%;
        height: 185px;
      }
      .title {
        margin-top: 15px;
        text-align: left;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #212121;
        line-height: 20px;
        &:hover {
          color: #317BFF;
        }
      }
      }
      
    }
  }
  // *******************学员反馈******************************** */

  // *******************中部菜单******************************** */
  .menu-block {
    position: sticky;
    padding: 24px 0;
    background-color: #fff;
    width: 100vw;
    z-index: 1001;
    border-bottom:  2px #EFEFEF solid;
    .menu-item{
      margin-right: 49px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
      line-height: 20px;
    }
    .menu-item.selected{
      color: #317BFF;
      font-weight: bold;
    }
    .consulting {
      width: 126px;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #FF7114;
      border-radius: 22px;
      color: #FF7114;
      &:hover {
        width: 126px;
        height: 44px;
        background: #FF7114;
        border-radius: 22px;
        color: #FFFFFF;
      }
    }
  }
  // *******************中部菜单******************************** */

  // *******************大纲列表******************************** */
  .outline-block {
    padding: 50px 0;
    .outline-half {
        width: 578px;
    .ouline-item-block {
      width: 100%;
        box-sizing: border-box;
        background: linear-gradient(45deg, #E2E2E2 0%, #F9F9F9 100%);
        &.show {
          margin-top: 52px;
          padding: 24px;
        }
          .title {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #317BFF;
          line-height: 20px;
          width: 100%;
        }
        .desc {
          margin-top: 13px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #000000;
          line-height: 20px;
          width: 100%;
          text-align: left;
        }

        .chapter-block {
          margin-top: 24px;
          width: 100%;
          .chapter-title {
            width: 100%;
            box-sizing: border-box;
            padding:  10px 26px;
            background: #FFFFFF;
            border-radius: 4px;
            text-align: left;
          }
          .chapter-item-container {
            display: none;
          }
          .content-item {
            margin-top: 30px;
            width: 100%;
            padding: 0 26px;
            box-sizing: border-box;
            text-align: left;
            &.first {
              margin-top: 26px;
            }
            &:hover {
              color: #317BFF;
            }
          }
        }
      }
      
    }
  }
  .hidden {
    visibility: hidden;
  }
  // *******************大纲列表******************************** */

  

</style>