<template>
  <div class="home-container flex-row flex-center">
    <!-- 轮播图 -->
    <home-swiper :pageMenus="pageMenus"></home-swiper>

  <!-- 菜单栏 -->
    <div class="menu-block flex-row flex-center" :style="isTop ? 'top:0px':'top:64px'">
      <div class="main-width flex-column flex-middle">
        <div v-for="menuItem,index in menuArr" :key="index" class="menu-item" :class="{'selected': index == selectedMenuIdx}" @click="menuTap(index)">{{menuItem.title}}</div>
      </div>
    </div>

  <!-- 视频 -->
    <div id="video-block" ref="video-block" class="video-block main-width">
      <video-player class="video-img main-width" 
            ref="videoPlayer" 
            :playsinline="true" 
            :options="playerOptions">
      </video-player>
      <!-- <img src="@/assets/home/index_video@2x.png" class="video-img main-width" /> -->
    </div>
  <!-- 视频End -->

  <!-- 简介 -->
  <div class="introduction-block main-width flex-row flex-center">
    <!-- 标题 -->
      <div class="title-view flex-column flex-middle">
        <div class="line"></div>
        <div class="text">「耳穴堂」  简介</div>
        <div class="line"></div>
      </div>
    <div class="content-block flex-column flex-middle flex-between">
      <img :src="introductionImg" class="img" />
      <div class="intro-text" v-html="introductionContent"></div>
    </div>
  </div>
  <!-- 简介End -->

    <!-- 释义 -->
  <div class="explain-block flex-row flex-center">
    <div class="content-main-block main-width flex-row flex-center">
    <!-- 标题 -->
      <div class="title-view flex-column flex-middle">
        <div class="line"></div>
        <div class="text">「耳穴库」   释义</div>
        <div class="line"></div>
      </div>
      <div class="explain-top-block flex-column flex-middle flex-between">
        <div class="explain-top-left flex-row flex-left">
          <div class="title">文字释义</div>
          <div class="sub-title"> 耳穴资料库的缩写，有耳穴“酷”的含义。
<div>成立网站的初心是建立国内专业的耳穴资料库，便于大家系统地查阅资料，达到资源共享、互动交流的目的，促进耳穴的推广与发展。 </div>
<div>耳穴库是所有酷爱耳穴朋友得力助手、互通平台，大家可以各自分享自己宝贵的耳穴资料与经验，共建我们的“耳穴互联网伐木累”，早日实现耳穴的“互联网+”和耳穴的“大数据”，造福更多百姓。</div></div>
          <div class="explain-top-icons flex-column flex-middle flex-between">
            <div class="explain-icons-item flex-column flex-middle">
              <img :src="explainIcon01" class="icon" />
              <div class="right-01 flex-row flex-left">
                <div class="title">品牌使命</div>
                <div class="title2">愿世人减少病痛</div>
              </div>
            </div>
            <div class="explain-icons-item flex-column flex-middle">
              <img :src="explainIcon02" class="icon" />
              <div class="right-01 flex-row flex-left">
                <div class="title">品牌愿景</div>
                <div class="title2">让耳穴之花开遍神州大地</div>
              </div>
            </div>
          </div>
        </div>
        <img :src="explainImg01" class="img-01" />
      </div>
      <div class="explain-top-block explain-bottom-block flex-column flex-middle flex-between">
        <img :src="explainImg02" class="img-01" />
        <div class="explain-top-left flex-row flex-left">
          <div class="title">LOGO释义</div>
          <div class="sub-title">耳郭是全身的一个缩影，耳穴在耳郭的分布犹如一个倒置在子宫内的胎儿。
<div>诺吉尔首先提出了“倒置胎儿”学说，引发全世界医学工作者对耳穴的关注与研究。是现代耳穴发展的原点。我们从原点出发继续探索与发扬耳穴诊治技术。</div></div>
        </div>
      </div>
    </div>
  </div>
  <!-- 释义End -->

  
  <!-- 团队 -->
  <div class="team-block main-width flex-row flex-center">
    <!-- 标题 -->
      <div class="title-view flex-column flex-middle">
        <div class="line"></div>
        <div class="text">耳穴堂   团队</div>
        <div class="line"></div>
      </div>
    <div class="content-block flex-column  flex-between flex-top">
      <div v-for="teamItem,teamIdx in teamArr" :key="teamIdx" class="team-item flex-row flex-center flex-ellipsis">
        <img :src="teamItem.img" class="team-img" />
        <div class="name">{{teamItem.name}}</div>
        <div class="tag-text">{{teamItem.tag}}</div>
        <div class="desc flex-row flex-center" v-html="teamItem.desc"></div>
      </div>
    </div>
  </div>
  <!-- 团队End -->

      <!-- 发展历程 -->
  <div class="develop-block flex-row flex-center">
    <div class="content-main-block main-width flex-row flex-center">
    <!-- 标题 -->
      <div class="title-view flex-column flex-middle">
        <div class="line"></div>
        <div class="text">发展历程</div>
        <div class="line"></div>
      </div>

      <!-- 年时间轴 -->
      <div class="year-block ">
        <div class="line">
          <div class="line-container flex-column flex-middle flex-around">
            <div v-for="yearItem,yearIdx in developArr" :key="yearIdx" class="dot" :class="{'selected' : yearIdx == developHoverIdx}" @mouseenter="yearTap(yearIdx)">
                <div class="year-text">{{yearItem.year}}</div>
            </div>
          </div>
        </div>
      </div>
              <!-- 月时间轴 -->
        <div class="year-selected">{{developArr[developHoverIdx].year}}</div>
        <div class="month-block">
          <!-- <div class="space-line"></div> -->
          <div v-for="monthItem,monthIdx in developHoverArr.steps" class="month-item flex-column flex-middle flex-between" :key="monthIdx">
            <div class="month-title">{{monthItem.month}}</div>
            <div class="content">{{monthItem.content}}</div>
          </div>
        </div>
      
    </div>
  </div>
  <!-- 发展历程End -->

    <!-- 耳穴堂风采 -->
  <div class="views-block main-width flex-row flex-center">
    <!-- 标题 -->
      <div class="title-view flex-column flex-middle">
        <div class="line"></div>
        <div class="text">耳穴堂风采</div>
        <div class="line"></div>
      </div>
    <div class="content-block flex-row  flex-center">
      <div class="title-text">{{views01Obj.title}}</div>
      <div class="img-block flex-column flex-middle flex-between">
          <img v-for="imgItem,imgIdx in views01Obj.imgArr" :key="imgIdx" :src="imgItem" class="img" :preview="1" />
      </div>
      <div class="title-text">{{views02Obj.title}}</div>
      <div class="img-block flex-column flex-middle flex-between">
          <img v-for="imgItem,imgIdx in views02Obj.imgArr" :key="imgIdx" :src="imgItem" class="img" :preview="2"/>
      </div>
    </div>
  </div>
  <!-- 耳穴堂风采End -->

      <!-- 产品体系 -->
       <production-list></production-list>
    <!-- 产品体系End -->
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import HomeSwiper from '../components/HomeSwiper.vue';
import ProductionList from '../components/ProductionList.vue';

export default {
  name: 'AboutUS',
  components: {
    HomeSwiper,
    videoPlayer,
    ProductionList
  },
  metaInfo: {
    mata: [
      {
        name: 'keywords',
        content: '耳穴培训,耳穴挂图,耳穴位,耳朵穴位图,耳朵穴位,耳针图谱,耳穴,耳针,耳针疗法,耳穴疗法,耳穴医学,耳医学,贴耳穴,学耳穴,耳穴联盟,耳穴加盟,耳疗,耳穴堂,耳穴库,耳穴淘宝,耳穴视频,耳穴培训,耳穴图,耳穴网站'
      },
      {
        name: 'description',
        content: '耳穴库网是耳穴公益性学习网站提供一站式耳穴培训,是青岛耳穴堂公司推出的集耳穴定位、疾病配方、耳穴培训,耳穴工具、视频教程、经验分享、传承基地、论坛于一体的综合性网站，耳穴库网致力成为国内专业的耳穴互联网平台。'
      }
    ]
  },
  computed: {
    developHoverArr() {
      return this.developArr[this.developHoverIdx]
    }
  },
  data() {
    return {
      // *******************banner******************************** *//
      pageMenus: [
        {
          name: '名称4',
          icon: 'https://static.cdn.erxueku.com/exk/9.jpg',
          bgColor: '#e8f7ff',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNWVlMDVkMjYzYmY1ZV9pa3VxWUI3MiIsImNoYW5uZWxfaWQiOiIiLCJyZXNvdXJjZV9pZCI6IiJ9'
        },
         {
          name: '名称4',
           icon: 'https://static.cdn.erxueku.com/exk/g2.png',
           bgColor: '#eeebe2',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNjAzMWI1YTllNGIwZjE3NmFlZDMwMjg3IiwiY2hhbm5lbF9pZCI6IiIsInJlc291cmNlX2lkIjoiIn0'
        },
        {
          name: '3d',
          icon: 'https://static.cdn.erxueku.com/exk/app3.png',
          bgColor: '#2f4469',
          url: 'https://mp.weixin.qq.com/s/3OMwW-6jEqPNbnwc_DrUDg'
        },
        {
          name: '商城',
          icon: 'https://static.cdn.erxueku.com/exk/sc4.png',
          bgColor: '#ecaf5c',
          url: 'https://shop45067782.m.youzan.com/v2/feature/iUUH2VMZTh?kdt_id=44875614&scan=3&from=singlemessage&sl=undefined&st=1&sf=wx_sm&is_share=1&share_cmpt=native_wechat&from_uuid=8f130a9d-4d9f-3ca7-07a8-a8981167a3ea'
        },
        {
          name: '小程序',
          icon: 'https://static.cdn.erxueku.com/exk/xcx5.png',
          bgColor: '#fdf5ec',
          url: 'https://apphmff2qch5525.h5.xiaoeknow.com/content_page/eyJ0eXBlIjozLCJhcHBfaWQiOiJhcHBoTWZmMlFjaDU1MjUiLCJyZXNvdXJjZV90eXBlIjoiIiwicHJvZHVjdF9pZCI6InBfNjAzMWI1YTllNGIwZjE3NmFlZDMwMjg3IiwiY2hhbm5lbF9pZCI6IiIsInJlc291cmNlX2lkIjoiIn0',
          alertType: 1
        }
      ],
      // *******************banner******************************** *//

      // *******************菜单栏******************************** *//
      isTop: false,//是否滑动菜单栏到顶部了
      selectedMenuIdx:0, //选中索引
      scrolltop: 0,//滑动距离顶部距离
      menuArr: [
        {
          title: '了解耳穴堂',
          eleId: 'video-block'
        }
      ],
      // *******************菜单栏******************************** *//

     // *******************视频******************************** *//
     playerOptions: {
            playbackRates: [], // 可选的播放速度
            autoplay: false, // 如果为true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 是否视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [{
              type: "video/mp4", // 类型
              src: 'https://static.cdn.erxueku.com/mp4/1%E5%85%AD%E5%88%86%E9%92%9F%E4%BA%86%E8%A7%A3%E4%BD%95%E4%B8%BA%E8%80%B3%E7%A9%B4%E7%96%97%E6%B3%95-%E5%8A%A0%E7%89%87%E5%A4%B4%E7%89%88.mp4' // url地址
            }],
            poster: require('@/assets/home/index_video@2x.png'), // 封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true, // 当前时间和持续时间的分隔符
              durationDisplay: true, // 显示持续时间
              remainingTimeDisplay: false, // 是否显示剩余时间功能
              fullscreenToggle: true // 是否显示全屏按钮
            }
          },
     // *******************视频******************************** *//

     // *******************简介******************************** *//
     introductionImg: require('@/assets/aboutUS/introduction.png'),
     introductionContent: '青岛耳穴堂健康管理有限公司成立于2014年5月。是国内目前现存较早的民办耳穴知识输出平台，也是国内耳穴培训领域具有影响力的机构之一。\
<div>具有独立完整的课程教学体系，可实现学员从理论学习→实践应用→技术加盟 的整体解决方案。</div>\
<div>通过系列公益活动、课程、讲座等形式，耳穴堂一直为推动国内耳穴诊治技术的传承与发展，做着持续不懈的努力。</div>\
<br />\
<div>2020年被写入人民卫生出版社出版的首部高校耳穴教材《耳穴诊治学》。</div>\
<div>多次举办承办耳穴大型会议，并持续不断的挖掘、总结耳穴知识，创新耳穴实用技术，获得业界人士的高度肯定与赞扬。</div>',
    // *******************简介******************************** *//

    // *******************耳穴堂释义******************************** *//
    explainIcon01: require('@/assets/aboutUS/explain01.png'),
    explainIcon02: require('@/assets/aboutUS/explain02.png'),
    explainImg01: require('@/assets/aboutUS/explain_img01.png'),
    explainImg02: require('@/assets/aboutUS/explain_img02.png'),
    // *******************耳穴堂释义******************************** *//

    // *******************团队******************************** *//
    teamArr: [
      {
        img: require('@/assets/aboutUS/team/liushipei.png'),
        name: '刘士佩',
        tag: '耳穴堂终身名誉顾问',
        desc: '<div>原中国针灸学会腧穴研究会耳穴研究组副组长</div>\
        <div>中国针灸学会耳穴诊治专业委员会第二、三、四、五届顾问</div>\
        <div>全国耳穴诊断组组长，安徽省《耳廓诊断》科 研协作组组长</div>\
        <div>香港耳针学会、《亚洲中国医药中心》、《亚洲医药杂志》高级医学顾问、教授。\
     合著《耳针》1972、1984两版、《耳穴诊断学》、《新编耳穴望诊彩色图谱》\
专著《耳廓诊治与养生》《刘氏望耳诊断学》《防治百病找耳穴》《耳廓诊治与养生》英文版\n</div>\
        '
      },
      {
        img: require('@/assets/aboutUS/team/wangzheng.png'),
        name: '王正',
        tag: '耳穴堂首席顾问',
        desc: '\
        <div>主任中医师、温州市名中医、浙江省名中医，浙江省名中医研究院研究员。</div>\
        <div>原浙江省针灸学会常务理事、耳穴专业委员会主任委员、全国耳穴诊治专业委员会常委、中国耳穴临床治疗研究组组长。\
     现任温州医科大学客座教授，全国耳穴诊治专业委员会顾问，浙江中医药大学附属温州中医院特需门诊专家等职。王正教授专门从事耳穴临床、教学和科研工作39年，参与制订“中国耳穴标准方案和国际耳穴标准方案”，发表论文40余篇，出版《中国耳穴诊治学》等四部专著、《耳处方手册》(内部资料)。\n</div>\
        '
      },
       {
        img: require('@/assets/aboutUS/team/zhudan.png'),
        name: '朱丹',
        tag: '耳穴堂终身名誉顾问',
        desc: '\
        <div>国家级名老中医传承导师、重庆市肿瘤医院针灸科主任医师、重庆市名老中医、中国针灸学会耳穴专委会常委、全国耳穴原诊断组组长、全国揿针专委会常委、重庆市针灸学会原副会长、重庆市针灸学会常务理事、重庆市耳穴专委会主任委员、《实用中医药》杂志编委、《耳穴研究》主编、重庆市名中医专委会常务理事、重庆市肿瘤医院针灸科负责人</div>\
        <div>从事临床、教学、科研50余年，曾获“全国耳穴研究杰出贡献奖”。撰写和发表论文60余篇，出版《耳穴诊治临床》、《实用耳穴诊治法》及《新编耳穴望诊彩色图谱》等5部学术专著，完成科研课题五项，获卫生科技进步奖两项。</div>\
        '
      },
      {
        img: require('@/assets/aboutUS/team/lixiujun.png'),
        name: '李秀君',
        tag: '耳穴堂顾问',
        desc: '\
        <div>主治医师，从医50年，耳针临床与教学近30年，80年代被北京宣武区科协聘为耳针讲师，为宣武区教育局组织的中、小学校医讲授耳针防治近视眼，疗效显著。师从广安门中医研究院针灸专家李志明，参与《耳穴诊治法》一书编写工作，由古籍出版社出版。</div>\
        <div>90年代受聘中国针灸学会和北京针灸学会，为宣武区卫生局、宣武区医学会、光明函授中医大学等单位讲授耳针课。92年北京针灸学会特聘耳穴疗法主讲师资。配合教学主编写《实用耳穴贴压疗法》讲义。</div>\
        <div>2008年为中国科学院基础所中医学校组织的耳针学习班授课，与高丽同志，合编《耳穴诊疗法》一书，由社会出版社出版。</div>\
        '
      },
      {
        img: require('@/assets/aboutUS/team/xuedingming.png'),
        name: '薛定明',
        tag: '耳穴堂顾问',
        desc: '\
        <div>北京耳穴专科门诊儿童近视治疗中心主任、空军总医院耳穴工作室主诊专家、原耳穴近视专科门诊部主任、中华医学会、中国针灸学会会员、中国耳穴专业委员会常务理事、全国高级针灸进修班耳穴综合疗法主讲、中国传统特色名医大丛书专家委员</div>\
        <div>北京电视台《金色时光》栏目耳穴保健法主讲人、主创者、被誉为耳穴刺血疗法专家，耳穴综合疗法治疗少年儿童近视创始人“治近圣手”。\n出版《耳穴自我按摩保健法》、《中国耳穴刺血疗法》、《一按百病消神奇耳穴》、《神奇耳穴-按摩与诊疗》四部著作及《耳穴诊疗法》、《儿童近视耳穴疗法》光盘，取得多项耳穴治疗器械的国家专利。</div>\
        '
      },
       {
        img: require('@/assets/aboutUS/team/lichao.png'),
        name: '李超',
        tag: '耳穴堂联合创始人、首席讲师',
        desc: '\
        <div>国家中医药适宜技术职业化培训工程耳穴讲师、北京厚朴学堂耳穴讲师、王正教授耳穴诊治第一代传承人。<br />\
        &emsp;&emsp;发表三篇耳穴论文《中欧耳穴结合贴压治疗小儿腺样体肥大53例疗效观察》、《耳穴磁珠贴压治疗身材矮小》、《耳穴针刺祛眼袋39例疗效观察》。2016年获得耳穴工作特殊贡献奖和耳穴临床经验先进奖。教学中将国标分区画入真人耳朵，创作《24节气耳穴与养生》、《耳穴意象推刮术》课程。 2020年创作了《培元固本耳廓按摩操》、《宁心耳廓按摩操》、《耳廓意象驻颜按摩术》、2022年创作了《耳穴调理腺样体肥大》、2022年创作了《儿童身高管理课程》。</div>\
        '
      },

      {
        img: require('@/assets/aboutUS/team/zhaokai.png'),
        name: '赵凯',
        tag: '耳穴堂创始人',
        desc: '\
        <div>青岛耳穴堂健康管理有限公司总经理、广东省针灸学会耳穴专业委员会委员、中国针灸学会耳穴诊治专业委员会会员</div>\
        <div>2016年成为王正教授第一代传承人，曾先后拜访王忠、刘士佩、王正、李秀君、许瑞征、张耕田、王槐昌、李家琪、刘继洪、钟新淮、黄丽春、乔正中，朱丹、于丽华、王民集、单秋华等耳穴前辈。</div>\
        <div>2019年赴美国拜访美国耳穴协会主席欧尔森和美国战场耳针创始人尼米兹莫，赴韩国拜访韩国耳针协会赵秋锡会长，去台湾拜访中华耳壳协会会长陈盈铭。\
发布国内首款耳穴小程序--耳穴库和3D耳穴软件，致力于耳穴发展历史的整理研究，耳穴互联网平台搭建、耳穴速记课程的研发、耳穴培训及推广。\n</div>\
        '
      },
      {
        img: require('@/assets/aboutUS/team/wangxiaoxi.png'),
        name: '王晓晞',
        tag: '耳穴堂特聘讲师',
        desc: '\
        <div>主任医师，王正耳穴传承中心主任。曾任苍南县耳穴学会副理事长。从事妇产科临床工作24年，长期研究耳穴诊治法在妇产科临床应用。任职于苍南县第二人民医院期间主持温州市科技局课题“耳穴磁珠贴压法矫正臀位的疗效观察”，发表论文多篇，参与《中国耳穴诊治学》，《耳穴辨治纲要》，《图解耳穴诊治与美容》和《耳穴诊治实践与成果》苍南篇等书的编写。</div>\
        '
      },
      {
        img: require('@/assets/aboutUS/team/liuxiaohui.png'),
        name: '刘晓辉',
        tag: '耳穴堂特聘讲师',
        desc: '\
        <div>主任护师，研究生学历，硕士学位，湖南中医药大学中西医结合博士在读，硕士生导师，现任河南中医药大学护理学院中医护理教研室主任，中国针灸学会耳穴专委会会员，国家二级心理咨询师。\
工作30年来，在中西医结合护理临床、护理管理及护理教育方面积累了丰富的经验，跟师河南针灸学会副主委和知名耳穴专家王民集教授。先后拜访学习了国内多位著名耳穴专家，在耳穴镇痛方面积累了一定的经验。\
主持参与省厅级科研项目18项，共发表论文35篇，以主编、副主编出版护理专著4部。\n</div>\
        '
      },
      {
        img: require('@/assets/aboutUS/team/wanghao.png'),
        name: '王浩',
        tag: '耳穴堂河北省教学中心主任',
        desc: '\
        <div>河北贞和堂耳穴创始人，中国针灸协会耳穴诊治专业委员会会员，河北省反射疗法协会耳穴专业委员会理事，耳穴刺血疗法专家、耳穴“治近圣手”薛定明教授学术传承人，耳穴堂河北省教学中心主任。</div>\
        <div>一线临床工作二十年，2016年跟随李超老师学习耳穴，之后弃药物而专耳穴，先后跟随薛定明老师，刘士佩老师，王正老师，朱丹老师深入研习耳穴，被薛定明教授收为弟子。临床擅长以耳穴为主防控近视，并结合其它外治疗法治疗各科杂症。</div>\
        '
      },
      {
        img: require('@/assets/aboutUS/team/liutairan.png'),
        name: '刘太然',
        tag: '耳穴堂河南教学中心主任',
        desc: '\
        <div>中西医结合临床医师、耳穴堂河南教学中心主任、中国针灸学会、耳穴诊治专业委员会会员、河南省民权县孙六镇胡同村卫生室负责人</div>\
        <div> 90年毕业于郑州仲景国医中等学院，从事儿科临床三十多年。自1992年至今一直从事中西临床工作。2015年起跟随耳穴堂李超老师学习钻研耳穴。随后参加了王正教授、刘士佩教授、朱丹教授的耳穴课程深入学习，专注研究耳穴镇痛及耳穴各种疾病的调理。并把前辈们好的经验运用于临床取得了很好的疗效，门诊量日益激增，在耳穴镇痛及耳穴各种疾病调理方面，得到了广大患者的认可和一致好评。</div>\
        '
      },
      {
        img: require('@/assets/aboutUS/team/shihongchen.png'),
        name: '石洪臣',
        tag: '耳穴堂河北教学中心副主任',
        desc: '\
        <div>河北省沧州市仁和诊所负责人，河北省反射疗法协会耳穴专业委员会会员，一线临床工作20余年，2015年跟随耳穴堂李超老师学习耳穴，先后跟随“耳穴望诊专家”刘士佩教授，浙江省名中医、全国耳穴医学杰出贡献奖、全国耳穴终身成就奖获得者王正教授等专家学习耳穴。临床擅长中药外治及耳穴治疗常见病多发病。</div>\
        '
      },
      {
        img: require('@/assets/aboutUS/team/lizhichao.png'),
        name: '李志超',
        tag: '耳穴堂讲师',
        desc: '\
        <div>中国针灸协会会员、青岛名中医王照中学术师承人、重庆名中医朱丹耳穴学术传承人、青岛大学医学院中医协会指导教师</div>\
        <div>师承于青岛老中医王照中先生，学习针灸，耳穴等中医非药物疗法。学习期间主攻耳穴疗法，并向多位老师学习，多次参加王正教授的高级耳穴传承班，朱丹教授高级耳穴传承班，李超医师耳穴高级班。临床跟诊于李超医师，“重庆名老中医”朱丹教授等，深入学习耳穴临床应用，2020年11月，成为“重庆名中医朱丹耳穴学术传承人”。</div>\
        <div>“耳穴必备中医基础知识”、“国标耳穴诊治初级班”课程讲师。同时受青岛大学医学部中医协会聘请，担任中医指导教师。</div>\
        '
      },
    ],
    // *******************团队******************************** *//

    // *******************发展历程******************************** *//
    developHoverIdx: 0,//选中的时间索引
    developArr: [
      {
        year: '2014',
        steps: [
          {
            month: '5月',
            content: '耳穴堂成立'
          },
          {
            month: '6月',
            content: '耳穴堂在 青岛海琴广场义诊一个半月'
          },
          {
            month: '7月',
            content: '耳穴泰斗王正教授首次在青岛讲耳穴课'
          },
          {
            month: '8月',
            content: '耳穴专家李秀君在青岛大枣园社区公益讲耳穴课并义诊'
          },
          {
            month: '8月',
            content: '台北中华耳壳协会会长陈盈铭赴青岛洽谈'
          },
        ]
      },
      {
        year: '2015',
        steps: [
          {
            month: '4月23日',
            content: '耳穴堂在青岛工商局注册成功'
          },
          {
            month: '5月',
            content: '第一期王正教授耳穴诊治高级传承班在青岛成功举办'
          },
          {
            month: '5月',
            content: '耳穴堂 淘宝店上线'
          },
          {
            month: '6月',
            content: '耳穴堂网站上线  www.erxueku.com'
          },
        ]
      },
      {
        year: '2016',
        steps: [
          {
            month: '3月',
            content: '耳穴堂首次举办王正耳穴诊治公益班'
          },
          {
            month: '5月',
            content: '耳穴堂第5期王正耳穴诊治高级班举办'
          },
          {
            month: '6月',
            content: '耳穴库网站更新上线'
          },
          {
            month: '9月',
            content: '耳穴堂首次在外地举办王正耳穴诊治培训班(上海)'
          },
          {
            month: '11月',
            content: '耳穴堂成功承办全国耳穴临床经验交流会暨耳穴知识竞赛和表彰授奖大会（温州）'
          },
          {
            month: '12月',
            content: '耳穴堂成功举办首期张福安耳穴培训班'
          },
        ]
      },
      {
        year: '2017',
        steps: [
          {
            month: '2月',
            content: '耳穴堂第15期耳穴诊治初级班在青岛举办，李超老师首次全程授课。'
          },
          {
            month: '4月',
            content: '耳穴堂首期刘士佩耳穴诊治传承班在巢湖成功举办'
          },
          {
            month: '6月',
            content: '耳穴堂第18期培训暨耳穴诊治高级班举办，李超老师首次全程讲耳穴高级课程'
          },
          {
            month: '7月',
            content: '耳穴堂赴沧州举办耳穴诊治初级班'
          },
          {
            month: '7月',
            content: '耳穴堂堂主在武汉给时代珍传讲授第一期国标耳穴定位班'
          },
          {
            month: '8月',
            content: '耳穴堂加入中国耳穴代表团赴新加坡参加第九届国际耳穴会议'
          },
          {
            month: '10月',
            content: '国内首款耳穴的小程序-耳穴库上线'
          },
          {
            month: '11月',
            content: '耳穴堂堂主赴深圳参加广东耳穴换届会议并发表 耳穴库的开发与使用技巧演讲'
          },
          {
            month: '12月',
            content: '耳穴堂李超老师被徐文兵老师创建的厚朴学堂聘请为耳穴讲师，给厚朴学员讲授耳穴课。'
          },
        ]
      },
      {
        year: '2018',
        steps: [
          {
            month: '1月',
            content: '耳穴堂第23期培训暨国标耳穴提升实战班在北京举办'
          },
          {
            month: '2月',
            content: '耳穴堂首次在郑州举办国标耳穴初级班'
          },
          {
            month: '3月',
            content: '耳穴堂在青岛举办第25期培训暨国标耳穴初级班'
          },
          {
            month: '4月',
            content: '耳穴堂在青岛举办第25期培训暨国标耳穴初级班'
          },
          {
            month: '5月',
            content: '耳穴堂第27期培训暨第三期刘士佩耳穴诊治传承班在合肥举办'
          },
          {
            month: '7月',
            content: '耳穴堂同时代珍传举办跨越30年两代耳穴人见面会'
          },
          {
            month: '8月',
            content: '耳穴堂代表团40余人参加中国针灸学会耳穴诊治专业委员会年会'
          },
          {
            month: '9月',
            content: '耳穴堂首次在宁波举办耳穴高级班'
          },
          {
            month: '10月',
            content: '耳穴堂第四期刘士佩耳穴诊治传承班在合肥举办'
          },
          {
            month: '11月',
            content: '耳穴堂朱丹耳穴诊治传承班首期在重庆举办'
          },
          {
            month: '12月',
            content: '耳穴堂堂主第12次赴武汉为时代珍传讲授第12期国标耳穴定位班'
          },
        ]
      },
      {
        year: '2019',
        steps: [
          {
            month: '2月',
            content: '耳穴堂第35期培训暨李超耳穴医学高级班在郑州举办'
          },
          {
            month: '4月',
            content: '耳穴堂首期薛定明耳穴医学传承班在济南举办'
          },
          {
            month: '5月',
            content: '耳穴堂第39期培训暨王正耳穴医学高级班在宁波举办'
          },
          {
            month: '6月',
            content: '耳穴堂堂主赴美国洛杉矶和华盛顿分别同美国耳针协会主席欧尔森和美国战场耳针创始人尼米兹窝交流'
          },
          {
            month: '8月',
            content: '耳穴堂一站式商城小程序开发上线'
          },
          {
            month: '9月',
            content: '耳穴堂第42期培训暨王正耳穴医学高级班在杭州举办'
          },
          {
            month: '9月',
            content: '耳穴堂拍摄庆祝建国70周年宣传片'
          },
          {
            month: '10月',
            content: '耳穴堂堂主赴韩国同韩国耳针协会会长赵秋锡交流'
          },
          {
            month: '11月',
            content: '耳穴堂第44期培训暨王正耳穴诊治初级公益班在北京举办，创造了260人的耳穴会议记录'
          },
          {
            month: '11月',
            content: '耳穴堂堂主赴台湾同中华耳壳协会会长 陈盈铭 交流'
          },
        ]
      },
      {
        year: '2020',
        steps: [
          {
            month: '1月',
            content: '耳穴堂线上课程平台开通，24节气与耳穴养生线上课正式开讲'
          },
          {
            month: '1月',
            content: '耳穴堂第一篇10万+公众号文章诞生-培元固本耳穴按摩操-耳穴按摩预防新型病毒'
          },
          {
            month: '1月',
            content: '耳穴堂向英雄致敬，全国41名前往一线的医务人员通过审核免费参加耳穴堂的培训班。'
          },
          {
            month: '2月',
            content: '公益直播：“解读耳朵泄露的秘密”、李超老师首创“耳廓意象驻颜按摩术”“宁心耳廓按摩操”、王浩老师“耳穴防控近视家庭版”'
          },
          {
            month: '3月',
            content: '公益直播：李超老师“育儿防病必备攻略”、“不懂这些千万不要学耳穴”、“乐活健康减肥”专题课程'
          },
          {
            month: '4月',
            content: '李超老师主讲的耳穴疗法初级线上课上线，受欢迎的耳穴入门课'
          },
          {
            month: '5月',
            content: '耳穴堂首创耳穴读书会上线'
          },
           {
            month: '6月',
            content: '耳穴堂一站式购物商城小程序 更新上线 '
          },
          {
            month: '7月',
            content: '耳穴堂第47期培训暨王正耳穴诊治高级班在杭州举办'
          },
          {
            month: '9月',
            content: '耳穴堂第47期培训暨王正耳穴诊治高级班在杭州举办'
          },
          {
            month: '9月',
            content: '耳穴堂启用新的教学体系，国标实操认证班在济南站举办'
          },
          {
            month: '9月',
            content: '耳穴堂堂主赴淮南为耳穴专家王忠老师扫墓'
          },
          {
            month: '10月',
            content: '耳穴堂”三个字写进人民卫生出版社出版的大学教材《耳穴诊治学》一书中，耳穴堂在广州举办国标实操认证班。'
          },
          {
            month: '10月',
            content: '朱丹耳穴公益班&朱丹耳穴传承人拜师仪式'
          },
          {
            month: '11月',
            content: '耳穴高级线上课之经验穴位篇开播'
          },
          {
            month: '11月',
            content: '王正教耳穴医学第4批传承人拜师仪式'
          },
          {
            month: '12月',
            content: '耳穴堂走进北京平谷卫生健康委员会分享耳穴诊治'
          },
          {
            month: '12月',
            content: '耳穴堂走进青岛大学中医协会，并设立耳穴奖学金。'
          },
        ]
      },
      {
        year: '2021',
        steps: [
          {
            month: '1月',
            content: '耳穴库更新2.0版上线'
          },
          {
            month: '2月',
            content: '邀请中国中医科学院针灸所荣培晶团队两场公益直播讲座'
          },
          {
            month: '3月',
            content: '李超老师耳穴诊治高级课-诊断开课'
          },
          {
            month: '3月',
            content: '国标耳穴实操认证班--南京站'
          },{
            month: '4月',
            content: '王正耳穴高级传承班在杭州举办耳穴堂成立7周年'
          },{
            month: '5月',
            content: '海口人民医院国标耳穴实操认证班专场'
          },{
            month: '6月',
            content: '南京溧水参加2021长三角健康峰会暨第二届中医药博览会，李超老师在大会上发言题目是战地耳针的临床应用'
          },{
            month: '7月',
            content: '耳穴堂第61期培训暨王正耳穴高级传承班-杭州站，与此同时举办王正教授第五批耳穴诊治传承人拜师仪式；耳穴堂首期经验耳穴实操班-衡水站；国标耳穴实操认证班-西安站'
          },{
            month: '8月',
            content: '李超老师首期耳穴诊断学习营开营，耳穴堂第四本讲义-耳穴诊断讲义印刷'
          },{
            month: '9月',
            content: '耳穴堂走进秦皇岛卫建委举办的乡村医生中医适宜技术培训班，北京近视实操班-王浩老师主讲'
          },{
            month: '10月',
            content: '耳穴堂浮山后旗舰店开业，耳穴腺样体肥大线上课专业版上线，耳穴国标实操认证班河南省中医院专场，耳穴堂第66期培训暨国标耳穴实操认证班-郑州站'
          },{
            month: '11月',
            content: '耳穴百病百讲专题课程开课'
          },{
            month: '12月',
            content: '耳针讲义印刷'
          },
        ]
      },
      {
        year: '2022',
        steps: [
          {
            month: '1月',
            content: '耳穴库新版上线1周年'
          },
          {
            month: '2月',
            content: '李超老师儿童身高管理课程上线'
          },
          {
            month: '4月',
            content: '耳穴堂首期诺吉尔耳廓疗法线上课开课，耳穴读书会第2季上线'
          },
          {
            month: '5月',
            content: '耳穴减肥学习营&耳穴健康体重管理体验营开营，耳穴堂耳穴疗法百病百讲讲义印刷'
          },
          {
            month: '8月',
            content: '耳穴国标实操认证班—聊城市第二人民医院专场'
          },
        ]
      },
    ],
    // *******************发展历程******************************** *//



    // *******************风采******************************** *//
    views01Obj: {
      title: '行业交流',
      imgArr: [
        require('@/assets/aboutUS/views/industry01.png'),
        require('@/assets/aboutUS/views/industry02.png'),
        require('@/assets/aboutUS/views/industry03.png'),
        require('@/assets/aboutUS/views/industry04.png'),
      ]
    },
    views02Obj: {
      title: '团队风采',
      imgArr: [
        require('@/assets/aboutUS/views/team01.jpg'),
        require('@/assets/aboutUS/views/team02.jpg'),
        require('@/assets/aboutUS/views/team03.jpg'),
        require('@/assets/aboutUS/views/team04.png'),
      ]
    },
    // *******************风采******************************** *//

    };
  },
  mounted(){
    // this.developHoverArr =  this.developArr[this.developHoverIdx]
    // 此处true需要加上，不加滚动事件可能绑定不成功
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
       let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
       if (scrolltop < this.scrolltop) {
         this.isTop = false
       }else {
        scrolltop > 428 ? (this.isTop = true) : (this.isTop = false);
       }
      this.scrolltop = scrolltop
    },
    itemClick() {
      // this.$router.push({
      //   name: item.component,
      //   params: {
      //     title: item.name,
      //   },
      // });
    },
    menuTap(index) {
      this.selectedMenuIdx = index
      let toId = this.menuArr[this.selectedMenuIdx].eleId
       window.scrollTo({
          //滚动到元素位置
          //top: this.$refs[pos][0].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
          top: this.$refs[toId].getBoundingClientRect().top + window.scrollY - 68,//推荐使用。getBoundingClientRect 相对于当前视口的位置
          //top: 400//滚动到指定距离
          //top: 0,//滚动到顶部
          //top: document.documentElement.scrollHeight,//滚动到底部
          behavior: "smooth" // 平滑滚动
        });
    },
    // *******************服务体系******************************** *//
    choiceTap(item) {
      this.choiceImg = item.img
    },
    // *******************服务体系******************************** *//

    // *******************发展历程******************************** *//
    yearTap(idx) {
      this.developHoverIdx = idx
    },
    // *******************发展历程******************************** *//


  },
}

</script>

<style lang="less">
  .home-container {
    width: 100vw;
  }

  // *******************标题******************************** */
    .title-view {
      .line {
        width: 21px;
        height: 3px;
        background: #E9E9E9;
      }
      .text {
        margin: 0 17px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #212121;
        line-height: 55px;
      }
    }
  // *******************标题******************************** */


  .pageControl-block {
    width: 100vw;
    background-color: #5e8af6;
  }

  .pageControl-content {
    position: relative;
    width: 100%;
    // height: 360px;
    .pageControl-content-wrapper {
      width: 100%;
      height: 100%;
      // margin: 0 200px 0 200px;
      .content-item {
        width: 100%;
        // float: left;
        text-align: center;
        background: rgba(255, 255, 255, 0.4);
        // cursor: pointer;
        .item-name {
          font-size: 18px;
          color: #222328;
          font-weight: 400;
          margin: 31px 0 38px 0;
        }
        .item-description {
          font-size: 16px;
          color: #3a3d4b;
        }
        .swiper-img {
          width: 1200px;
          height: 360px;
        }
      }
    }
  }

  .swiper-button-prev, .swiper-button-next{
    top: 0;
    bottom: 0;
    color: #fff;
    margin: auto;
  }

  //穿透 所有在.wrapper下面的.swiper-pagination-bullet-active更改小圆点的背景颜色
.pageControl /deep/ .swiper-pagination-bullet-active {
  background: #fff;
}

  // *******************中部菜单******************************** */
  .menu-block {
    position: sticky;
    padding: 24px 0;
    background-color: #fff;
    width: 100vw;
    z-index: 1001;
    .menu-item{
      margin-right: 49px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
      line-height: 20px;
    }
    .menu-item.selected{
      color: #317BFF;
    }
  }
  // *******************中部菜单******************************** */

  // *******************视频区******************************** */
  .video-block {
    margin-bottom: 90px;
    .video-img {
      height: 675px;
      border-radius: 10px;
      overflow: hidden;
    }
  }
  .video-js .vjs-big-play-button {
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  // *******************视频区******************************** */

  // *******************简介******************************** */
  .introduction-block {
    padding-bottom: 70px;
    .content-block {
      width: 100%;
      .img {
        width: 458px;
        height: 324px;
      }
      .intro-text {
        flex: 1;
        margin-left: 26px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #666666;
        line-height: 20px;
        text-align: left;
        text-indent:2em;
      }
    }
  }
  // *******************简介******************************** */

  // *******************释义******************************** */
  .explain-block {
    width: 100vw;
    background: #F3F4F6;
    padding: 60px 0 70px;
    .content-main-block {
      .explain-top-block {
        .explain-top-left {
          flex:1;
          .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #444444;
            line-height: 20px;
          }
          .sub-title {
            margin-top: 18px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            text-align: left;
            text-indent:2em;
          }
          .explain-top-icons {
            margin-top: 42px;
            width: 100%;
            .explain-icons-item {
              .icon {
                width: 46px;
                height: 46px;
              }
              .right-01 {
                margin-left: 20px;
                .title {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #444444;
                  line-height: 20px;
                }
                .title2 {
                  margin-top: 9px;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #666666;
                  line-height: 20px;
                }
              }
            }
          }
        }
        .img-01 {
          margin-left: 43px;
          width: 421px;
          height: 210px;
        }
      }
      .explain-bottom-block {
        margin-top: 37px;
        .img-01 {
          margin-left: 0;
          margin-right: 35px;
        }
      }
    }
  }
  // *******************释义******************************** */

  // *******************团队******************************** */
  .team-block {
    padding: 60px 0 70px;
    .content-block {
      margin-top: 25px;
      width: 100%;
      .team-item {
        width: 33%;
        padding: 58px 30px;
        box-sizing: border-box;
        // height: 850px;
        overflow: hidden;
        
        // transition: all 20s;
        &:hover {
          transition: all .6s;
          margin-top: -25px;
          background: rgba(255,255,255,.4);
          -webkit-box-shadow: 0 3px 24px rgba(0,0,0,.15);
          box-shadow: 0 3px 24px rgba(0,0,0,.15);
          border-radius: 8px;
          height: auto;
          .team-img {
            transition: all .6s;
            width: 220px;
            height: 220px;
          }
          .desc {
            transition: all .6s;
            overflow: initial;
            text-overflow: initial;
            line-height: 1.6;
            /*! autoprefixer: off */
            display: initial;
            -webkit-line-clamp: initial;
            -webkit-box-orient: initial;
          }
        }
        .team-img {
          width: 332px;
          height: 332px;
        }
        .name {
          margin-top: 24px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 20px;
        }
        .tag-text {
          margin-top: 14px;
          min-width: 207px;
          padding: 6px 42px;
          border: 2px solid #317BFF;
          border-radius: 12px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #317BFF;
          line-height: 20px;
        }
        .desc {
          overflow: hidden;
          text-overflow: ellipsis;
          /*! autoprefixer: off */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 100%;
          margin-top: 32px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          text-indent:2em;
          text-align: left;
          // flex: 1;
          div {
            width: 100%;
            white-space: normal;
          }
        }
      }
    }
  }
  // *******************团队******************************** */

  // *******************发展历程******************************** */
  .develop-block {
    width: 100vw;
    background: #F3F4F6;
    padding: 60px 70px;
    box-sizing: border-box;
    .year-block {
      margin-top: 50px;
      width: 100%;
      background: #2E2E2E;
      border-radius: 5px;
      .line {
        position: relative;
        margin: 40px 0 54px;
        width: 100%;
        height: 2px;
        background: #999999;
        .line-container {
          position: absolute;
          top: -7px;
          left: 0;
          width: 100%;
          height: 16px;
          .dot {
            position: relative;
            width: 16px;
            height: 16px;
            background: #999999;
            border-radius: 50%;
            .year-text {
              position: absolute;
              top: 24px;
              left: -10px;
              margin: auto;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              color: #929292;
              line-height: 20px;
            }
            &.selected {
              background: #317BFF;
              .year-text {
              color: #317BFF;
              }
            }
          }
        }
      }
    }
    .year-selected {
      margin-top: 37px;
      width: 100%;
      text-align: left;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #317BFF;
      line-height: 20px;
    }
    .month-block {
      position: relative;
      width: 100%;
      margin-top: 38px;
      max-height: 450px;
      overflow-y: auto;
      white-space: nowrap;
      .space-line {
        position: absolute;
        left: 17px;
        top: 28px;
        height: 100%;
        width: 2px;
        background: #D8D8D8;
      }
      .month-item {
        width: 100%;
        margin-top: 28px;
        white-space: normal;
        .month-title {
          position: relative;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #666666;
          line-height: 20px;
          background: #F3F4F6;
          // padding: 14px 0;
          z-index: 10;
          width: 80px;
        }
        &:not(:last-child) .month-title:before {
            content: '';
            position: absolute;
            top: calc(100% + 10px);
            left: 55%;
            width: 1px;
            height: 35px;
            background: #D8D8D8
        }
        .content {
          width: 1094px;
          padding: 15px 24px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 5px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #333333;
          line-height: 20px;
          text-align: left;
          &:hover {
            color: #317BFF;
          }
        }
      }
    }
  }
  // *******************发展历程******************************** */


  // *******************耳穴堂风采******************************** */
  .views-block {
    padding: 60px 0 70px;
    .content-block {
      width: 100%;
      .title-text {
        margin-top: 33px;
        width: 100%;
        text-align: left;
      }
      .img-block {
        margin-top: 17px;
        width: 100%;
        .img {
          width: 282px;
          height: 216px;
          border-radius: 4px;
          object-fit: cover;
          &:hover {
            -webkit-transform: scale(1.15);
            -ms-transform: scale(1.15);
            transform: scale(1.15);
          }
        }
      }
    }
  }
  // *******************耳穴堂风采******************************** */


</style>